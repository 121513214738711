<template>
  <validation-observer
    ref="form"
    v-slot="{ invalid }"
  >
    <slot name="title">
    </slot>

    <v-form>
      <password-field
        label="New password"
        v-model="password"
      ></password-field>
      <password-confirm-field
        label="Confirm password"
        v-model="passwordConfirm"
      ></password-confirm-field>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="invalid || processing"
          :loading="processing"
          color="primary"
          @click.prevent="onSubmit"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import PasswordField from "@/components/forms/inputs/Password";
import PasswordConfirmField from "@/components/forms/inputs/PasswordConfirm";

export default {
  name: "ConfirmPasswordForm",
  components: {
    PasswordField,
    PasswordConfirmField
  },
  props: ["processing"],
  data: () => ({
    invalid: false,
    password: "",
    passwordConfirm: ""
  }),
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        this.$emit("clicked", {
          password: this.password,
          passwordConfirm: this.passwordConfirm
        });
      });
    }
  }
};
</script>