<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import AppLayout from "@/layouts/AppLayout";
import PublicAppLayout from "@/layouts/PublicAppLayout";

const DEFAULT_LAYOUT = "app-layout";

export default {
  name: "App",
  components: {
    EmptyLayout,
    AppLayout,
    PublicAppLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout || DEFAULT_LAYOUT;
    }
  },
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: "%s | " + process.env.VUE_APP_NAME
  }
};
</script>
