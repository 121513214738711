import router from "@/router";
import { ROLES } from "@/components/enums";
import { request } from "@/services/api";

const getDefaultState = () => {
  return {
    isAuthorized: false,
    user: {}
  };
};

const state = getDefaultState();

function _processSuccessfulLogin(commit, tokenData) {
  commit("setAuthorized");
  commit("setAccessToken", tokenData?.access_token);

  router.push("/");
}

function _processSuccessfulLogout(commit) {
  commit("unsetAuthorized");
  commit("unsetUser");

  router.push("/login");
}

export default {
  name: "auth",
  state,
  actions: {
    initAction({ getters }, payload) {
      // We don't want to make unnecessary request to the backend in case it's
      // a public page, it may effect Page Load Time
      if (!payload.isPublicPage && getters.isAuthorized) {
        return this.dispatch("getCurrentUser").catch(() => {
          console.log("Wasn't able to receive user data");
        });
      } else {
        return Promise.resolve();
      }
    },

    getCurrentUser({ commit, rootState }, options = {}) {
      options.headers = {
        Authorization: `Bearer ${this.getters.accessToken}`
      };

      return request
        .get(`${rootState.apiPrefix}/users/me`, options)
        .then(responseBody => {
          commit("setUser", responseBody);
        });
    },

    updateCurrentUser({ commit, state, rootState }, userData) {
      return request
        .patch(`${rootState.apiPrefix}/users/me`, userData)
        .then(responseBody => {
          const userData = responseBody?.data,
            mergedUserData = { ...state.user, ...userData };

          commit("setUser", mergedUserData);
        });
    },

    getToken({ commit, rootState }, credentials) {
      return request
        .post(`${rootState.apiPrefix}/auth/token`, credentials, {
          handle4xx: false
        })
        .then(responseBody => {
          if (responseBody) {
            _processSuccessfulLogin(commit, responseBody);
          } else {
            console.error("Missing token data");
          }
        });
    },

    deleteToken({ commit, rootState }, options = {}) {
      options.headers = {
        Authorization: `Bearer ${this.getters.accessToken}`
      };

      return request
        .delete(`${rootState.apiPrefix}/auth/token`, {}, options)
        .then(() => {
          _processSuccessfulLogout(commit);
        });
    },

    silentLogout({ commit }) {
      _processSuccessfulLogout(commit);

      return Promise.reject("Logout");
    },

    forcePageReload() {
      location.reload();

      return Promise.resolve({});
    },

    sendPasswordResetLink({ rootState }, credentials) {
      return request.post(
        `${rootState.apiPrefix}/auth/forgot-password`,
        credentials
      );
    },

    resetPassword({ rootState }, { token, credentials }) {
      return request
        .post(
          `${rootState.apiPrefix}/auth/reset-password/${token}`,
          credentials,
          {
            handle4xx: false
          }
        )
        .then(() => {
          router.push("/login");
        })
        .catch(error => {
          const errors = error?.response?.data?.errors ?? [
            { message: "Unable to reset password" }
          ];

          return Promise.reject(errors?.[0]?.message);
        });
    },

    operationForbidden() {
      router.push({ name: "404" });
      return Promise.reject("Forbidden");
    }
  },
  getters: {
    isAuthorized(state) {
      return state.isAuthorized;
    },

    userData(state) {
      return state.user;
    },

    accessToken(state) {
      return state.accessToken;
    }
  },

  mutations: {
    setAuthorized(state) {
      state.isAuthorized = true;
    },

    unsetAuthorized(state) {
      state.isAuthorized = false;
    },

    setUser(state, data) {
      state.user = data;
    },

    setAccessToken(state, token) {
      state.accessToken = token;
    },

    unsetUser(state) {
      state.user = {};
    }
  }
};
