<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Model mapping
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center action-buttons">
        <template v-if="!sceneLoaded">
          <v-btn disabled>New group</v-btn>
        </template>
        <template v-else>
          <v-dialog
            v-model="partDialog"
            persistent
            max-width="400"
            v-blur-all-on-close-dialog
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                New group
              </v-btn>
            </template>
            <v-card elevation="0">
              <v-toolbar dark class="headline" color="primary">
                <v-toolbar-title>New group</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <v-container>
                  <v-select
                    v-model="partData.name"
                    :items="partTypes"
                    label="Group"
                  ></v-select>

                  <fieldset class="color-picker-wrapper">
                    <legend>Group color</legend>
                    <v-color-picker
                      v-model="partData.color"
                      hide-inputs
                      hide-sliders
                      mode="hexa"
                      show-swatches
                      :swatches="swatches"
                    ></v-color-picker>
                  </fieldset>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="onPartDialogClose">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click.prevent="onPartCreate"
                  :disabled="!partData.name"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <v-btn
          :disabled="!modelChanged || isSaving"
          :loading="isSaving"
          @click="onSaveModel"
          color="primary"
          >Save</v-btn
        >
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-row v-if="isMobile">
        <div id="unity-mobile-warning">
          WebGL builds are not supported on mobile devices.
        </div>
      </v-row>
      <v-row v-else-if="model">
        <v-col>
          <span class="scene-progress-wrapper" v-if="!sceneLoaded">
            <v-progress-circular
              :size="25"
              color="#ddd"
              indeterminate
              class="scene-progress-spinner"
            ></v-progress-circular>
            <p class="scene-progress-text loading">loading</p>
          </span>
          <unity
            :unity="unityContext"
            width="720px"
            height="720px"
            class="unity-canvas"
          />
        </v-col>
        <v-col v-if="model">
          <div class="tree-wrapper">
            <v-treeview
              v-model="treeSelection"
              :items="partsTree"
              dense
              selectable
              return-object
              :key="treeKey"
              open-all
              class="overflow-auto"
            >
            </v-treeview>
          </div>
        </v-col>
        <v-col>
          <fieldset class="fieldset-wrapper parts-list">
            <legend>Groups</legend>
            <v-chip
              v-for="part in parts"
              :key="part"
              class="ma-2"
              label
              close-icon="mdi-trash-can-outline"
              :close="part !== unknownPartId"
              :disabled="!sceneLoaded"
              :color="part in partsColors ? partsColors[part] : 'grey'"
              :dark="partColorIsDark(partsColors[part])"
              v-on="
                part !== unknownPartId
                  ? { click: () => onPartsAssign(part) }
                  : {}
              "
              @click:close="onPartDelete(part)"
            >
              {{ part }}
            </v-chip>
          </fieldset>

          <fieldset class="fieldset-wrapper materials-list">
            <legend>Materials</legend>

            <v-form ref="materials">
              <v-select
                v-for="({ items, label, disabled, value },
                partName) in materials"
                :key="partName"
                item-text="asset_name"
                item-value="asset_name"
                :items="items"
                :placeholder="label"
                :value="value"
                height="16"
                dense
                :disabled="!sceneLoaded || disabled"
                v-on="{
                  change: value =>
                    setMaterial(null, value, partName, true, true)
                }"
                :ref="partName"
              ></v-select>
            </v-form>
          </fieldset>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import UnityWebgl from "unity-webgl";
import { mapActions } from "vuex";
import tinycolor from "tinycolor2";
import { groupBy, deepCopy, sliceObjectKeys } from "@/components/helpers";
import { PART_TYPES, PART_COLOR_MAP } from "@/components/enums";

const getDefaultPartData = () => {
  return {
    name: null,
    color: null
  };
};

const BASE_COLORS = Object.values(
  sliceObjectKeys(PART_COLOR_MAP, key => key.startsWith(PART_TYPES.base))
);

const GEM_COLORS = Object.values(
  sliceObjectKeys(PART_COLOR_MAP, key => key.startsWith(PART_TYPES.gem))
);

const LAYER_COLORS = Object.values(
  sliceObjectKeys(PART_COLOR_MAP, key => key.startsWith(PART_TYPES.layer))
);

const Unity = new UnityWebgl({
  loaderUrl: "/Build/web-configurator-unity-attributes.loader.js",
  dataUrl: "/Build/web-configurator-unity-attributes.data",
  frameworkUrl: "/Build/web-configurator-unity-attributes.framework.js",
  codeUrl: "/Build/web-configurator-unity-attributes.wasm"
});

export default {
  name: "ModelPartsMapping",

  components: {
    unity: UnityWebgl.vueComponent
  },

  data() {
    return {
      partDialog: false,
      isMobile: false,
      sceneLoaded: false,
      treeSelection: [],
      treeKey: 0, // an undocumented feature that rebuilds the tree and resets its inner state after nodes moving
      model: null,
      modelFile: null,
      modelChanged: false,
      isSaving: false,
      unityContext: Unity,
      swatches: [
        BASE_COLORS.slice(0, 3),
        BASE_COLORS.slice(3, 5).concat(GEM_COLORS.slice(0, 1)),
        GEM_COLORS.slice(1, 4),
        GEM_COLORS.slice(4, 5).concat(LAYER_COLORS.slice(0, 2)),
        LAYER_COLORS.slice(2, 5)
      ],
      partTypes: Object.values(PART_TYPES),
      unknownPartId: "unknown",
      partData: getDefaultPartData(),
      partsColors: {},
      selectedRootParts: new Set(),
      materials: {
        [PART_TYPES.base]: {
          items: [],
          label: "Metals",
          disabled: true,
          value: null
        },
        [PART_TYPES.gem]: {
          items: [],
          label: "Stones",
          disabled: true,
          value: null
        },
        [PART_TYPES.layer]: {
          items: [],
          label: "Layers",
          disabled: true,
          value: null
        }
      }
    };
  },

  watch: {
    treeSelection: function(newValue, oldValue) {
      let difference;
      const increasing = newValue.length > oldValue.length;

      if (increasing) {
        difference = this.getArrayObjectsDifference(newValue, oldValue);
      } else {
        difference = this.getArrayObjectsDifference(oldValue, newValue);
      }

      if (increasing) {
        for (const item of newValue) {
          this.unityContext.send("MainController", "SelectMesh", item.id);
          this.selectedRootParts.add(this.getTreeItemParent(item));
        }

        this.highlightSelectedPartsMaterials();
        this.switchMaterialsDisabledState();
      } else {
        this.highlightMultipleModelElements(
          difference.map(item => item.id),
          null,
          true,
          true
        );

        difference.map(item => {
          this.unityContext.send("MainController", "UnSelectMesh", item.id);
          this.clearMaterialsIfEmptySelection(
            this.getTreeItemParent(item),
            newValue
          );
          this.selectedRootParts.delete(this.getTreeItemParent(item));
        });

        // when deselecting multiple parent nodes it should display the remaining nodes' material
        this.highlightSelectedPartsMaterials();
        // ..and disable all materials dropdowns
        this.switchMaterialsDisabledState();
      }
    }
  },

  computed: {
    parts() {
      return Object.keys(this.model.parts).sort();
    },

    partsTree() {
      let tree = [];

      for (const [part, nodes] of Object.entries(this.model.parts)) {
        tree.push({
          id: part,
          name: part,
          disabled: !this.sceneLoaded,
          children: nodes.map(node => ({
            id: node,
            name: this.skipRootNode(node),
            parent: part
          }))
        });
      }

      return tree;
    }
  },

  methods: {
    ...mapActions(["getModel", "upsertModel", "getMaterials"]),

    registerUnityFunctions() {
      const script = document.createElement("script");
      script.setAttribute("async", "");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", "/js/unity-functions.js");
      document.body.appendChild(script);
    },

    skipRootNode(node) {
      return node
        .split("/")
        .slice(1)
        .join("/");
    },

    getTreeItemParent(item) {
      return item.parent || item.id;
    },

    getPartParent(part) {
      for (const [parent, values] of Object.entries(this.model.parts)) {
        if (values.includes(part)) {
          return parent;
        }
      }
    },

    getArrayObjectsDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.id === object2.id;
        });
      });
    },

    async getFileFromUrl(url, name) {
      const response = await fetch(url);
      const data = await response.blob();

      return new File([data], name, {
        type: data.type
      });
    },

    async downloadModelFile(url) {
      console.log(`Downloading model file from ${url}`);
      const fileName = new URL(url).pathname.split("/").slice(-1);
      this.modelFile = await this.getFileFromUrl(url, fileName);
      console.log(`Downloaded model file from ${url}`);
    },

    clearModelFile() {
      this.modelFile = null;
    },

    isUnknownPart(partName) {
      return partName === this.unknownPartId;
    },

    async loadScene() {
      if (!this.modelFile) {
        console.log("Nothing to load");
        return;
      }

      console.log("Loading scene");

      const reader = new FileReader();
      const unity = this.unityContext;

      reader.onload = (function(f) {
        return function(e) {
          (window.filedata = window.filedata ? window.filedata : {})[f.name] =
            e.target.result;
          unity.send("MainController", "FileUpload", f.name);
        };
      })(this.modelFile);

      reader.readAsArrayBuffer(this.modelFile);

      console.log("Loaded scene");
    },

    async loadModel(modelId) {
      try {
        console.log(`Loading model ${modelId}`);
        this.model = await this.getModel(modelId);
        this.setPartsColors();
        console.log(`Loaded model ${modelId}`);
      } catch (error) {
        console.error("Could not load model.", error);
        await this.$router.push({ name: "404" });
      }
    },

    async loadMaterials() {
      let allMaterials = [];
      const params = {
        page: 1,
        size: 100
      };
      const materials = await this.getMaterials(params);
      const totalPages = materials.metadata.pagination.total_pages;
      let restMaterials = {};

      if (totalPages > 1) {
        let promises = [];

        for (let page = 2; page < totalPages + 1; page++) {
          params.page = page;
          promises.push(this.getMaterials(params));
        }

        restMaterials = await Promise.all(promises);
      }

      allMaterials = [...materials.items];

      for (const restMaterialsSubset of restMaterials) {
        allMaterials = [...allMaterials, ...restMaterialsSubset.items];
      }

      const groupedByTarget = groupBy(allMaterials, "target");

      for (const [groupName, items] of Object.entries(groupedByTarget)) {
        this.materials[groupName].items = items;
      }
    },

    async onSaveModel() {
      let save = true;
      const hasEmptyParts = !Object.values(this.model.parts).every(
        v => v.length
      );

      if (hasEmptyParts) {
        save = await this.$confirm(
          "Empty groups will be deleted. Continue editing or accept.",
          {
            buttonTrueText: "Continue",
            buttonFalseText: "Cancel",
            persistent: true
          }
        );
      }

      if (save) {
        for (const [part, nodes] of Object.entries(this.model.parts)) {
          if (!nodes.length) {
            delete this.model.parts[part];
          }
        }

        try {
          this.isSaving = true;

          this.model = await this.upsertModel({
            id: this.model.id,
            parts: this.model.parts,
            default_materials: this.model.default_materials
          });

          this.setModelChanged(false);
        } catch (error) {
          console.log(`Could not save model. Error: ${error.message}`);
        } finally {
          this.isSaving = false;
        }
      }
    },

    resetPartDialogData() {
      this.partData = getDefaultPartData();
    },

    onPartDialogClose() {
      this.partDialog = false;
      this.resetPartDialogData();
    },

    setModelChanged(state = true) {
      this.modelChanged = state;
    },

    partInDefaultMaterials(part) {
      return (
        this.model.default_materials instanceof Object &&
        part in this.model.default_materials
      );
    },

    selectedPartsHaveSameType() {
      return (
        Array.from(
          new Set(
            Array.from(this.selectedRootParts).map(part =>
              this.getPartTypeFromName(part)
            )
          )
        ).length === 1
      );
    },

    selectedPartsHaveSameMaterial() {
      return (
        Array.from(
          new Set(
            Array.from(this.selectedRootParts).map(
              part => this.model.default_materials[part]
            )
          )
        ).length === 1
      );
    },

    setPartsColors() {
      for (const part of Object.keys(this.model.parts)) {
        if (part === this.unknownPartId) {
          continue;
        }

        if (part in PART_COLOR_MAP) {
          this.partsColors[part] = PART_COLOR_MAP[part];
        } else {
          let colorGroup;

          if (part.startsWith(PART_TYPES.base)) {
            colorGroup = BASE_COLORS;
          } else if (part.startsWith(PART_TYPES.gem)) {
            colorGroup = GEM_COLORS;
          } else if (part.startsWith(PART_TYPES.layer)) {
            colorGroup = LAYER_COLORS;
          }

          if (colorGroup) {
            let randomGroupColor =
              colorGroup[Math.floor(Math.random() * colorGroup.length)];

            this.partsColors[part] = tinycolor(randomGroupColor)
              .lighten(35)
              .toString();
          }
        }
      }
    },

    partColorIsDark(color) {
      return tinycolor(color).isDark();
    },

    createPart(partId) {
      const partName = `${this.partData.name}${partId}`;
      console.log("Creating new part: " + partName);

      this.model.parts[partName] = [];
      this.partsColors[partName] = this.partData.color.hex;
      this.model.parts = { ...this.model.parts };
    },

    getPartTypeFromName(part) {
      return part.split(/[0-9]+/)[0];
    },

    onPartCreate() {
      let partId = 1;

      for (const part of this.parts.reverse()) {
        if (part.startsWith(this.partData.name)) {
          const match = part.match(/[0-9]+$/);
          partId = parseInt(match[0]) + 1;
          break;
        }
      }

      this.createPart(partId);
      this.setModelChanged();
      this.onPartDialogClose();
      this.resetPartDialogData();
    },

    onPartsAssign(part) {
      if (this.treeSelection.length) {
        const selectedItems = this.treeSelection
          .map(el => el.id)
          .filter(el => !this.parts.includes(el));

        let oldParts = new Set();

        for (const item of selectedItems) {
          for (const [oldPart, nodes] of Object.entries(this.model.parts)) {
            if (nodes.includes(item)) {
              oldParts.add(oldPart);
            }
          }
        }

        if (oldParts.size === 1) {
          const oldPart = [...oldParts][0];

          for (const item of selectedItems) {
            for (const nodes of this.model.parts[oldPart]) {
              if (nodes.includes(item)) {
                const oldPartIndex = this.model.parts[oldPart].findIndex(
                  el => el === item
                );

                if (oldPartIndex !== -1) {
                  this.model.parts[oldPart].splice(oldPartIndex, 1);
                }
              }
            }

            this.model.parts[part].push(item);
          }

          this.setModelChanged();
        } else {
          this.$confirm("Only one group's items are allowed to re-assign.", {
            buttonTrueText: "OK",
            buttonFalseText: "",
            persistent: true
          });
        }

        this.treeSelection = [];
        this.treeKey++;
      }
    },

    async onPartDelete(part) {
      const answer = await this.$confirm(
        `Are you sure you want to delete '${part}'`,
        {
          buttonTrueText: "Continue",
          buttonFalseText: "Cancel",
          persistent: true
        }
      );

      if (!answer) {
        return;
      }

      const index = this.parts.indexOf(part);

      if (index !== -1) {
        if (this.model.parts[part].length) {
          if (!(this.unknownPartId in this.model.parts)) {
            this.model.parts[this.unknownPartId] = this.model.parts[part];
          } else {
            this.model.parts[this.unknownPartId] = [
              ...this.model.parts[this.unknownPartId],
              ...this.model.parts[part]
            ];
          }
        }

        this.highlightMultipleModelElements(
          this.model.parts[part],
          null,
          false,
          false
        );

        delete this.model.parts[part];
        delete this.partsColors[part];

        if (this.partInDefaultMaterials(part)) {
          delete this.model.default_materials[part];
          this.model.default_materials = deepCopy(this.model.default_materials);
        }

        this.model.parts = deepCopy(this.model.parts);
        this.setModelChanged();
      }
    },

    onImportSucceed() {
      if (!this.model) {
        console.log("Missing model");
        return;
      }

      for (const [part, nodes] of Object.entries(this.model.parts)) {
        if (this.partInDefaultMaterials(part)) {
          const partType = this.getPartTypeFromName(part);

          for (const node of nodes) {
            this.setMaterial(
              node,
              this.model.default_materials[part].toLowerCase(),
              partType
            );
          }
        } else {
          let partColor = this.partsColors[part];

          if (partColor) {
            for (const node of nodes) {
              this.highlightModelElement(node, partColor);
            }
          }
        }
      }

      this.sceneLoaded = true;
    },

    onModelPartClick(event, isCtrlPressed = false) {
      const item = {
        id: event.detail,
        name: this.skipRootNode(event.detail),
        parent: this.getPartParent(event.detail)
      };

      if (isCtrlPressed) {
        const index = this.treeSelection.findIndex(obj => obj.id === item.id);

        if (index !== -1) {
          this.treeSelection.splice(index, 1);
          this.unityContext.send("MainController", "UnSelectMesh", item.id);

          const hasOtherSelectedItemsOfCurrentParent = this.treeSelection.filter(
            el => el.parent === item.parent
          ).length;

          if (!hasOtherSelectedItemsOfCurrentParent) {
            this.selectedRootParts.delete(item.parent);
          }

          this.highlightMultipleModelElements([item.id], null, true, true);
        } else {
          this.unityContext.send("MainController", "SelectMesh", item.id);
          this.selectedRootParts.add(item.parent);
          this.treeSelection.push(item);
        }
      } else {
        this.selectedRootParts.clear();
        this.treeSelection = [];
        this.unityContext.send("MainController", "SelectMesh", item.id);
        this.selectedRootParts.add(item.parent);
        this.treeSelection.push(item);
      }
    },

    setSingleNodeMaterial(node, material) {
      const value = {
        element: node,
        material: material
      };

      this.unityContext.send(
        "MainController",
        "SetUpMaterial",
        JSON.stringify(value)
      );

      console.log("Changed material:", node, material);
    },

    setMaterial(
      node,
      material,
      part,
      resetSelection = false,
      updateModel = false
    ) {
      const partType = this.getPartTypeFromName(part);
      console.log("Changing material", material, part);

      if (node === null) {
        for (const item of this.treeSelection) {
          this.setSingleNodeMaterial(item.id, material);
        }
      } else {
        this.setSingleNodeMaterial(node, material);
      }

      if (updateModel) {
        if (this.selectedRootParts.size) {
          if (!(this.model.default_materials instanceof Object)) {
            this.model.default_materials = {};
          }

          for (const partName of this.selectedRootParts) {
            this.model.default_materials[partName] = material;
          }

          this.model.default_materials = deepCopy(this.model.default_materials);
          this.setModelChanged();
        }
      }

      if (resetSelection) {
        this.treeSelection = [];
        this.$refs[partType][0].blur();
        this.$refs["materials"].reset();
      }
    },

    updateMaterialsState(property, value, exclude) {
      exclude = Array.isArray(exclude) ? exclude : [];

      for (let partType in this.materials) {
        if (exclude.length) {
          if (!exclude.includes(partType)) {
            this.materials[partType][property] = value;
          }
        } else if (!this.treeSelection.length) {
          this.materials[partType][property] = value;
        }
      }
    },

    highlightSelectedPartsMaterials() {
      /**
       * Set selected parts materials in the 'Materials' section.
       */
      for (const partName of this.selectedRootParts) {
        if (this.isUnknownPart(partName)) {
          continue;
        }

        const partType = this.getPartTypeFromName(partName);
        let material = this.model.default_materials?.[partName];

        if (!material) {
          continue;
        }

        if (
          this.selectedPartsHaveSameType() &&
          this.selectedPartsHaveSameMaterial()
        ) {
          console.log(
            `Setting current material choices value to '${material}' for part type '${partType}'`
          );
          this.materials[partType].value = material;
        } else {
          console.log(
            `Unsetting current material choices value for part type '${partType}'`
          );
          this.materials[partType].value = null;
        }

        this.updateMaterialsState("value", null, [partType]);
      }

      if (!this.selectedRootParts.size) {
        this.updateMaterialsState("value", null);
      }
    },

    switchMaterialsDisabledState() {
      /**
       * Enable or disable the corresponding dropdown list in the 'Materials' section.
       */
      if (this.treeSelection.length) {
        for (const partName of this.selectedRootParts) {
          if (this.isUnknownPart(partName)) {
            continue;
          }

          const partType = this.getPartTypeFromName(partName);
          this.materials[partType].disabled = !this.selectedPartsHaveSameType();
          this.updateMaterialsState("disabled", true, [partType]);
        }
      } else {
        this.updateMaterialsState("disabled", true);
      }
    },

    clearMaterialsIfEmptySelection(part, selectedParts) {
      if (this.isUnknownPart(part)) {
        return;
      }

      const partType = this.getPartTypeFromName(part);

      if (!selectedParts.length) {
        console.log(`Unsetting ${partType} material`);
        this.materials[partType].value = null;
      }
    },

    highlightModelElement(elementName, color) {
      const value = {
        element: elementName,
        hexColor: color
      };

      this.unityContext.send(
        "MainController",
        "SetUpColor",
        JSON.stringify(value)
      );
    },

    highlightMultipleModelElements(
      elementNames,
      color = null,
      useDefaultColor = true,
      useDefaultMaterial = false
    ) {
      for (const [part, nodes] of Object.entries(this.model.parts)) {
        const defaultMaterial = this.model.default_materials?.[
          part
        ]?.toLowerCase();

        const partType = this.getPartTypeFromName(part);
        // @TODO use item.parent and get rid of outer loop
        for (const node of elementNames) {
          if (nodes.includes(node)) {
            if (useDefaultMaterial && defaultMaterial) {
              this.setMaterial(node, defaultMaterial, partType);
            } else if (!useDefaultMaterial) {
              console.log("Clearing material on group delete", node);
              this.unityContext.send("MainController", "ClearMaterial", node);
            } else {
              if (useDefaultColor) {
                color = this.partsColors[part];
              }

              this.highlightModelElement(node, color);
            }
          }
        }
      }
    }
  },

  async mounted() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      this.isMobile = true;
      return;
    }

    this.registerUnityFunctions();

    await this.loadModel(this.$route.params.modelId);

    this.unityContext
      .on("progress", percent => {})
      .on("loaded", async () => {
        console.log("Unity Instance: Loaded.");
        await this.loadMaterials();
      })
      .on("created", async () => {
        console.log("Unity Instance: Created.");
      })
      .on("destroyed", () => {
        console.log("Unity Instance: Destroyed.");
      });

    document.addEventListener("sceneLoaded", async () => {
      await this.downloadModelFile(this.model.url);
      await this.loadScene();
      this.clearModelFile();
    });

    document.addEventListener("importSucceed", async () => {
      this.onImportSucceed();
      // this.enableMaterials();
    });

    document.addEventListener("selectTreeElement", this.onModelPartClick);
    document.addEventListener("selectTreeElementCtrl", event =>
      this.onModelPartClick(event, true)
    );
  },

  beforeDestroy() {
    console.log("Destroying component..");
    Unity.destroy();
  },

  metaInfo: {
    title: "Model mapping"
  }
};
</script>

<style lang="sass">
.v-treeview
  .v-treeview-node__root
    min-height: 25px !important

  .v-treeview-node__label
    font-size: 12px

  .v-treeview-node__content
    margin-left: 3px !important

  .v-treeview-node__checkbox
    margin-left: 0 !important

  .v-icon:after
    top: 3px !important
    left: 3px !important
    width: 70% !important
    height: 70% !important

.tree-wrapper
  height: 720px
  width: 335px
  overflow-y: auto
  background: #f8f8f8
  border: 1px solid #ddd

.fieldset-wrapper
  background: #f8f8f8
  border: 1px solid #ddd
  padding-left: 5px
  padding-right: 5px

.parts-list
  overflow-y: scroll
  width: 280px
  max-height: 300px

  .v-chip
    display: flex
    max-width: 85px

.materials-list
  max-width: 280px

  .v-select
    max-width: 280px

  form
    width: 268px

.color-picker-wrapper

  legend
    font-size: 16px
    padding-left: 5px
    padding-right: 5px

  .v-color-picker__swatches > div
    justify-content: end

  .v-color-picker__canvas
    margin-left: 20px

.unity-canvas
  background: #000124

.unity-mobile .unity-canvas
  width: 100%
  height: 100%

#unity-mobile-warning
  position: absolute
  left: 50%
  top: 5%
  transform: translate(-50%)
  padding: 10px

.scene-progress-wrapper
  .scene-progress-spinner
    position: absolute
    top: 25px
    left: 15px

  .scene-progress-text
    position: absolute
    top: 25px
    left: 50px
    color: #ddd
</style>
