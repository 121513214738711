<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    scrollable
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" @click="onDialogOpen">
        Import
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Import
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-stepper v-model="wizardStepId">
        <v-stepper-items>
          <import-data-step
            :model="model"
            @dialog-closed="onDialogClose"
            @update-model="updateModel"
          ></import-data-step>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import ImportDataStep from "@/components/import/steps/ImportDataStep";
import { mapGetters } from "vuex";
import { IMPORT_WIZARD_STEPS } from "@/components/enums";

export default {
  name: "ImportWizard",

  components: { ImportDataStep },

  data: () => ({
    dialog: false,
    wizardStepId: IMPORT_WIZARD_STEPS.import,
    model: null,
    fileWrapper: {
      file: null,
      valid: false
    }
  }),

  methods: {
    ...mapGetters(["getNewImportModel"]),

    onDialogOpen() {
      this.setWizardStep(IMPORT_WIZARD_STEPS.import);
    },

    onDialogClose() {
      this.dialog = false;
      this.setWizardStep("");
      this.model = this.getNewImportModel();
      this.$emit("dialog-closed");
    },

    setWizardStep(value) {
      this.wizardStepId = value;
    },

    updateModel(value) {
      this.model = value;
      this.resolveNextStep();
    },

    resolveNextStep() {
      this.setWizardStep(IMPORT_WIZARD_STEPS.import);
    }
  },

  created() {
    if (!this.model) {
      this.model = this.getNewImportModel();
    }
  }
};
</script>

<style lang="sass">
.v-stepper__content .v-card__text
  min-height: 450px

.notransition
  -webkit-transition: none !important
  -moz-transition: none !important
  -o-transition: none !important
  transition: none !important
</style>
