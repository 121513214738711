<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Collections
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center">
        <collection-dialog
          button-text="New collection"
          :callback="onDialogClose"
        ></collection-dialog>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="table-secondary-column">
              <span>{{ item.id }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.name | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.description | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <v-simple-checkbox
                v-model="item.access_rules.is_public"
                disabled
              ></v-simple-checkbox>
            </td>
            <td class="table-secondary-column">
              <collection-dialog
                button-icon="mdi-pencil-circle"
                :model-id="item.id"
                :callback="onDialogClose"
              ></collection-dialog>
              <v-btn
                color="primary"
                icon
                small
                @click="onCollectionDelete(item)"
              >
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import CollectionDialog from "@/pages/collections/CollectionDialog";

export default {
  name: "CollectionsList",

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  components: { CollectionDialog },

  data: () => ({
    loading: false,
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "id"
      },
      {
        text: "Name",
        sortable: false
      },
      {
        text: "Description",
        sortable: false
      },
      {
        text: "Public",
        sortable: false
      },
      {
        text: null,
        align: "end",
        sortable: false,
        width: "7%"
      }
    ]
  }),

  methods: {
    ...mapActions(["getCollections", "deleteCollection"]),

    getResultsActionName() {
      return "getCollections";
    },

    onDelete(collection) {
      this.loading = true;
      this.deleteCollection(collection.id).then(() => {
        this.fetchData();
      });
    },

    onCollectionDelete(collection) {
      this.$confirm("Are you sure you want to remove this collection?", {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      }).then(result => {
        if (result) {
          this.onDelete(collection);
        }
      });
    },

    onDialogClose() {
      this.fetchData();
    }
  },

  metaInfo: {
    title: "Collections"
  }
};
</script>

<style scoped></style>
