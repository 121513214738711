<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Models
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center action-buttons">
        <import-wizard @dialog-closed="onDialogClose"></import-wizard>
        <span>
          <v-btn
            :loading="downloading"
            :disabled="downloading"
            color="primary"
            @click="onExportClick"
          >
            Export
          </v-btn>
        </span>
        <model-dialog
          button-text="New model"
          :callback="onDialogClose"
        ></model-dialog>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title class="filter">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="table-secondary-column">
              <span>{{ item.id }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.name | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <span class="ucwords" :class="{ loading: isProcessing(item) }">{{
                item.conversion_result
                  ? item.conversion_result.status
                  : null | default_value
              }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.custom_id | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <v-btn
                color="primary"
                icon
                small
                :disabled="!isConverted(item)"
                :to="{
                  name: 'ModelPartsMapping',
                  params: {
                    modelId: item.id
                  }
                }"
              >
                <v-icon>mdi-unity</v-icon>
              </v-btn>
              <model-dialog
                button-icon="mdi-pencil-circle"
                :model-id="item.id"
                :callback="onDialogClose"
                :button-disabled="isProcessing(item)"
              ></model-dialog>
              <v-btn color="primary" icon small @click="onModelDelete(item)">
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { MODEL_CONVERSION_STATUS } from "@/components/enums";
import ModelDialog from "@/pages/models/ModelDialog";
import ImportWizard from "@/components/import/ImportWizard";

export default {
  name: "ModelsList",

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  components: { ImportWizard, ModelDialog },

  data: () => ({
    loading: false,
    downloading: false,
    autoRefreshResults: true,
    conversionStatuses: {
      converted: MODEL_CONVERSION_STATUS.converted,
      processing: MODEL_CONVERSION_STATUS.processing
    },
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "id"
      },
      {
        text: "Name",
        sortable: false
      },
      {
        text: "Conversion status",
        sortable: false,
        width: "10%"
      },
      {
        text: "Custom ID",
        sortable: false,
        value: "custom_id"
      },
      {
        text: null,
        align: "end",
        sortable: false,
        width: "10%"
      }
    ]
  }),

  methods: {
    ...mapActions(["getModels", "deleteModel", "contentExportExecute"]),

    getResultsActionName() {
      return "getModels";
    },

    onDelete(model) {
      this.loading = true;
      this.deleteModel(model.id).then(() => {
        this.fetchData();
      });
    },

    onModelDelete(model) {
      this.$confirm("Are you sure you want to remove this model?", {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      }).then(result => {
        if (result) {
          this.onDelete(model);
        }
      });
    },

    onDialogClose() {
      this.fetchData();
    },

    onExportClick() {
      this.downloading = true;

      this.contentExportExecute().finally(() => (this.downloading = false));
    },

    hasConversionResult(model) {
      return Boolean(model.conversion_result);
    },

    isProcessing(model) {
      return (
        this.hasConversionResult(model) &&
        model.conversion_result.status === this.conversionStatuses.processing
      );
    },

    isConverted(model) {
      return (
        this.hasConversionResult(model) &&
        model.conversion_result.status === this.conversionStatuses.converted
      );
    }
  },

  metaInfo: {
    title: "Models"
  }
};
</script>

<style lang="sass">
.ucwords
  text-transform: capitalize

.action-buttons button:first-child
  margin-right: 10px

.filter
  width: 300px
</style>
