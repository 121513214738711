import { request } from "@/services/api";
import ResourcesService from "@/services/resources";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

export default {
  name: "items",
  state,
  actions: {
    getItems({ getters, rootState }, params) {
      // Add validation to prevent send unused params to API
      const validParamKeys = ["page", "size", "filter[collection_id]"];
      const validParams = {};

      validParamKeys.forEach(key => {
        if (params[key]) {
          validParams[key] = params[key];
        }
      });

      const options = {
        params: validParams,
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/items`, options);
    },

    getItem({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/items/${id}`, options);
    },

    deleteItem({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.delete(`${rootState.apiPrefix}/items/${id}`, {}, options);
    },

    upsertItem({ getters, rootState }, data) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      const isNewRecord = data.id === null;
      const method = isNewRecord ? "post" : "put";
      const pathSuffix = isNewRecord ? "" : `/${data.id}`;
      const itemData = {
        id: data.id,
        name: data.name,
        description: data.description,
        short_description: data.short_description,
        thumbnail_key: data.thumbnail_key,
        price: data.price,
        model_id: data.model_id,
        collection_id: data.collection_id
      };

      if (data.thumbnail.file) {
        return ResourcesService.uploadFile(
          data.thumbnail.file,
          data.thumbnail.presignedData
        ).then(() => {
          return request[method](
            `${rootState.apiPrefix}/items${pathSuffix}`,
            itemData,
            options
          );
        });
      } else {
        return request[method](
          `${rootState.apiPrefix}/items${pathSuffix}`,
          itemData,
          options
        );
      }
    }
  },

  getters: {},

  mutations: {}
};
