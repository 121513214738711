<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Items
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center">
        <item-dialog
          button-text="New item"
          :callback="onDialogClose"
        ></item-dialog>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <collections-select
          item-value="id"
          item-text="name"
          label="Filter by collection"
          clearable
          @change="onItemsFilterByCollection"
        ></collections-select>
      </v-card-title>

      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="table-secondary-column">
              <span>{{ item.id }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.name | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.short_description | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.price | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <item-dialog
                button-icon="mdi-pencil-circle"
                :model-id="item.id"
                :callback="onDialogClose"
              ></item-dialog>
              <v-btn color="primary" icon small @click="onItemDelete(item)">
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import ItemDialog from "@/pages/items/ItemDialog";
import CollectionsSelect from "@/pages/items/components/CollectionsSelect";

export default {
  name: "ItemsList",

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  components: { ItemDialog, CollectionsSelect },

  data: () => ({
    loading: false,
    currentCollectionId: null,
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "id"
      },
      {
        text: "Name",
        sortable: false,
        value: "name"
      },
      {
        text: "Short Description",
        sortable: false,
        value: "short_description"
      },
      {
        text: "Price",
        sortable: false,
        value: "price"
      },
      {
        text: null,
        align: "end",
        sortable: false,
        width: "7%"
      }
    ]
  }),

  methods: {
    ...mapActions(["getItems", "deleteItem"]),

    getResultsActionName() {
      return "getItems";
    },

    getQueryParams() {
      return Object.assign(GridUtils.methods.getQueryParams.call(this), {
        "filter[collection_id]": this.currentCollectionId
      });
    },

    onDelete(item) {
      this.loading = true;
      this.deleteItem(item.id).then(() => {
        this.fetchData();
      });
    },

    onItemDelete(item) {
      this.$confirm("Are you sure you want to remove this item?", {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      }).then(result => {
        if (result) {
          this.onDelete(item);
        }
      });
    },

    onDialogClose() {
      this.fetchData();
    },

    onItemsFilterByCollection(collectionId) {
      this.currentCollectionId = collectionId;
      this.options.page = 1;
      this.fetchData();
    }
  },

  metaInfo: {
    title: "Items"
  }
};
</script>

<style scoped></style>
