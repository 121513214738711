<template>
  <v-tooltip
    right
    :disabled="tooltipDisabled"
    :nudge-top="tooltipNudge"
    :open-delay="tooltipOpenDelay"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-group
        :prepend-icon="item.icon"
        :color="item.color || 'primary'"
        :group="item.routeGroupRegex"
        :value="expanded"
        v-bind="attrs"
        v-on="on"
        ref="group"
        @mouseup="onGroupClick"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-tooltip
          right
          v-for="(nestedItem, i) in item.nestedItems"
          :key="i"
          :disabled="tooltipDisabled"
          :open-delay="tooltipOpenDelay"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              link
              :exact="!nestedItem.highlightDuringPartialRouteMatch"
              :to="{ name: nestedItem.routerName }"
              :class="{
                'router-link-active': nestedItem.routerName === $route.name,
                'nested-list-item': showNestedItemsLeftMargin
              }"
              class="list-item text-body-1"
              active-class="active-list-item"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>{{ nestedItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ nestedItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ nestedItem.onHoverTitle || nestedItem.title }}</span>
        </v-tooltip>
      </v-list-group>
    </template>
    <span>{{ item.onHoverTitle || item.title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SidebarMenuGroup",

  props: {
    item: {
      type: Object
    },
    tooltipDisabled: {
      type: Boolean,
      default: false
    },
    tooltipOpenDelay: {
      type: Number,
      default: 0
    },
    showNestedItemsLeftMargin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tooltipNudge: 0
    };
  },

  computed: {
    expanded() {
      if (this.item.routeGroupRegex instanceof RegExp) {
        return this.item.routeGroupRegex.exec(this.$route.path) ?? false;
      }
      return false;
    }
  },

  methods: {
    // Calculate top nudge for tooltip. Otherwise if group is opened - tooltip will be in the middle of group
    calcNudge(expanded) {
      // this value get from node_modules/vuetify/src/components/VTooltip/_variables.scss:8 +50ms
      const tooltipTransitionDuration = 200;

      // this value get from node_modules/vuetify/src/components/VList/_variables.scss:17
      const listItemHeight = 48;

      setTimeout(() => {
        if (!expanded) {
          this.tooltipNudge = 0;
        } else {
          this.tooltipNudge =
            (this.item.nestedItems.length * listItemHeight) / 2;
        }
      }, tooltipTransitionDuration);
    },

    onGroupClick() {
      this.calcNudge(!this.$refs?.group.isActive);
    }
  },

  watch: {
    // Calculate initial tooltip nudge when page loaded
    expanded: {
      immediate: true,
      handler: function(val) {
        this.calcNudge(val);
      }
    }
  }
};
</script>

<style scoped></style>
