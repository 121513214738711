const getDefaultState = () => {
  return {
    loading: false
  };
};

const state = getDefaultState();

export default {
  name: "loading",
  state,
  actions: {
    setGlobalLoading({ commit }, loading) {
      commit("setLoading", loading);
    }
  },

  getters: {
    globalLoading(state) {
      return state.loading;
    }
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    }
  }
};
