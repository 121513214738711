<template>
  <v-dialog v-model="dialog" persistent v-blur-all-on-close-dialog>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        small
        dark
        v-bind="attrs"
        v-on="on"
        v-if="buttonIcon"
        :disabled="buttonDisabled"
        ref="activator"
      >
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        :disabled="buttonDisabled"
        v-bind="attrs"
        v-on="on"
        ref="activator"
        v-else
      >
        {{ buttonText }}
      </v-btn>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-overlay :value="loading" :absolute="true" :opacity="0.17">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-card-text>
          <v-form ref="form" :disabled="loading">
            <v-row>
              <v-col cols="6">
                <text-field
                  label="Name"
                  name="name"
                  v-model="formData.name"
                  validation-rules="required|max:64"
                  autocomplete="off"
                ></text-field>

                <file-field
                  :validation-rules="fileRules"
                  label="Model file"
                  name="file"
                  v-model="file"
                  @change="onFileInputChanged"
                >
                </file-field>

                <text-field
                  label="Scale"
                  name="scale"
                  v-model.number="formData.scale"
                  validation-rules="required|float|min_value:0"
                  autocomplete="off"
                ></text-field>

                <text-field
                  label="Position"
                  name="position"
                  hint="A comma-separated X,Y,Z coordinates. Example: 0,0,0"
                  validation-rules="required|coordinates"
                  :value="formData.position"
                  autocomplete="off"
                  @change="onPositionChange"
                ></text-field>

                <text-field
                  label="Rotation"
                  name="rotation"
                  hint="A comma-separated X,Y,Z coordinates. Example: 0,0,0"
                  validation-rules="required|coordinates"
                  :value="formData.rotation"
                  autocomplete="off"
                  @change="onRotationChange"
                ></text-field>

                <text-field
                  label="Custom ID"
                  name="custom_id"
                  v-model="formData.custom_id"
                  validation-rules="max:50"
                  autocomplete="off"
                ></text-field>

                <text-area-field
                  label="Parts with materials mapping"
                  name="parts_with_materials_mapping"
                  v-model="partsWithMaterialsMappingStr"
                  validation-rules="object"
                  rows="2"
                ></text-area-field>

                <text-area-field
                  label="Default materials"
                  name="default_materials"
                  v-model="defaultMaterialsStr"
                  validation-rules="object"
                  rows="2"
                ></text-area-field>
              </v-col>

              <v-col>
                <text-area-field
                  label="Parts (readonly)"
                  name="parts"
                  rows="21"
                  readonly
                  v-model="partsStr"
                ></text-area-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClosed">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="processing"
            :disabled="invalid || processing || !changed"
            @click.prevent="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { RESOURCE_TYPES } from "@/components/enums";
import { deepCopy, objectsEqual, timeout } from "@/components/helpers";
import TextField from "@/components/forms/inputs/TextField";
import FileField from "@/components/forms/inputs/FileField";
import TextAreaField from "@/components/forms/inputs/TextArea";
import ResourcesService from "@/services/resources";

const getDefaultFormData = () => {
  return {
    name: null,
    key: null,
    scale: null,
    position: null,
    rotation: null,
    custom_id: null,
    parts_with_materials_mapping: null,
    default_materials: null,
    parts: null
  };
};

export default {
  name: "ModelDialog",

  components: {
    TextField,
    FileField,
    TextAreaField
  },

  data: () => ({
    title: null,
    loading: false,
    processing: false,
    invalid: false,
    dialog: false,
    changed: false,
    file: null,
    formData: Object.assign({}, getDefaultFormData()),
    initialFormData: null,
    presignedData: null,
    partsWithMaterialsMappingStr: null,
    defaultMaterialsStr: null,
    partsStr: null,
    fileRules: {
      size: process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE,
      extensions: ["glb", "fbx"]
    }
  }),

  props: {
    modelId: {
      type: Number,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    buttonIcon: {
      type: String,
      default: null
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    }
  },

  watch: {
    dialog: function(value) {
      if (value) {
        if (this.modelId) {
          this.loading = true;
          this.getModel(this.modelId)
            .then(model => {
              this._initiateFormData(model);
            })
            .finally(() => (this.loading = false));
        }
      }
    },

    formData: {
      deep: true,
      handler: function() {
        this.changed = !objectsEqual(this.formData, this.initialFormData);
      }
    },

    defaultMaterialsStr: function(newValue) {
      this._reflectJsonFieldChange(
        "defaultMaterialsStr",
        "default_materials",
        newValue
      );
    },

    partsWithMaterialsMappingStr: function(newValue) {
      this._reflectJsonFieldChange(
        "partsWithMaterialsMappingStr",
        "parts_with_materials_mapping",
        newValue
      );
    },

    partsStr: function(newValue) {
      this._reflectJsonFieldChange("partsStr", "parts", newValue);
    }
  },
  methods: {
    ...mapActions(["upsertModel", "getModel"]),

    _jsonify(value, defaultValue = null) {
      try {
        return JSON.parse(value);
      } catch {
        return defaultValue;
      }
    },

    _parseCoordinates(value) {
      return value
        .toString()
        .split(",")
        .filter(el => el !== "")
        .map(Number)
        .filter(el => !isNaN(el));
    },

    _prettyPrint(value) {
      return value ? JSON.stringify(deepCopy(value), undefined, 2) : "";
    },

    _resetFormData() {
      this.$refs.form.reset();
      this.$refs.validator.reset();
    },

    _initiateFormData(model) {
      const formData = {
        name: model.name,
        key: null,
        custom_id: model.custom_id,
        scale: model.scale,
        position: model.position,
        rotation: model.rotation,
        parts_with_materials_mapping: model.parts_with_materials_mapping,
        default_materials: model.default_materials,
        parts: model.parts
      };

      this.formData = deepCopy(formData);
      this.initialFormData = deepCopy(formData);

      this._initializeJsonFields();
    },

    _initializeJsonFields() {
      const fieldsMapping = {
        defaultMaterialsStr: "default_materials",
        partsWithMaterialsMappingStr: "parts_with_materials_mapping",
        partsStr: "parts"
      };

      for (const [formStringField, formDataField] of Object.entries(
        fieldsMapping
      )) {
        this[formStringField] = this._prettyPrint(this.formData[formDataField]);
      }
    },

    _reflectJsonFieldChange(stringField, formField, value) {
      try {
        this.formData[formField] = this._jsonify(value);
      } catch (err) {
        this[stringField] = this._jsonify(this.formData[formField]);
      }
    },

    _setFileRules() {
      if (!this.modelId) {
        this.fileRules["required"] = true;
      }
    },

    _fixDisabledButtonStyle() {
      if (this.buttonDisabled) {
        // fixes the transparent button if it is disabled
        this.$refs.activator.$el.classList.remove("theme--dark");
        this.$refs.activator.$el.style.opacity = 0.2;
      }
    },

    onSubmit() {
      if (this.$refs.validator.validate()) {
        this.processing = true;

        this.upsertModel({
          id: this.modelId ?? null,
          name: this.formData.name,
          custom_id: this.formData.custom_id ? this.formData.custom_id : null,
          key: this.formData.key,
          scale: this.formData.scale,
          position: this._parseCoordinates(this.formData.position),
          rotation: this._parseCoordinates(this.formData.rotation),
          parts_with_materials_mapping: this.formData
            .parts_with_materials_mapping,
          default_materials: this.formData.default_materials,
          file: {
            file: this.file,
            presignedData: this.presignedData
          }
        })
          .then(() => {
            timeout(20).then(() => {
              this.onClosed();
              this.callback && this.callback();
            });
          })
          .catch(error => {
            const errors = error?.response?.data?.errors ?? [];
            const parsedErrors = Object.fromEntries(
              errors.map(el => [el.field, el.message])
            );
            this.$refs.validator.setErrors(parsedErrors);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    onClosed() {
      this.dialog = false;
      this._resetFormData();
    },

    async onFileInputChanged(file) {
      if (file) {
        this.file = file;
        this.presignedData = await ResourcesService.getPresignedData(
          file.name,
          RESOURCE_TYPES.model
        );

        if (this.presignedData) {
          this.formData.key = this.presignedData?.fields?.key ?? null;
        }
      }
    },

    onPositionChange(value) {
      this.formData.position = this._parseCoordinates(value);
    },

    onRotationChange(value) {
      this.formData.rotation = this._parseCoordinates(value);
    }
  },

  created() {
    this._setFileRules();
    this.title = !this.modelId ? "New model" : "Edit model";
  },

  mounted() {
    this._fixDisabledButtonStyle();
  }
};
</script>

<style scoped lang="sass">
.fixed-opacity
  opacity: 0.2
</style>
