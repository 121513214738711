<template>
  <ValidationProvider
    :label="$attrs.label"
    :name="name"
    :counter="counter"
    :rules="validationRules"
  >
    <v-textarea
      slot-scope="{ errors }"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
      :rows="rows"
      outlined
    ></v-textarea>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "TextAreaField",

  components: {
    ValidationProvider
  },

  props: {
    value: {
      type: null
    },
    counter: {
      type: String,
      default: "25"
    },
    validationRules: {
      type: [String, Object]
    },
    name: {
      type: String
    },
    rows: {
      type: String,
      default: "4"
    }
  },

  data: () => ({
    innerValue: ""
  }),

  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    }
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
