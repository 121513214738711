import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import Meta from "vue-meta";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { date } from "@/filters/date";
import { default_value } from "@/filters/default_value";
import { formatBytes } from "@/filters/files";
import { BlurAllOnCloseDialogDirective } from "@/directives/BlurAllOnCloseDialog";

Vue.config.productionTip = true;

Vue.use(Meta);

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Install filters globally
Vue.filter("date", date);
Vue.filter("default_value", default_value);
Vue.filter("format_bytes", formatBytes);

// Load directives
Vue.directive("blurAllOnCloseDialog", BlurAllOnCloseDialogDirective);

export const VueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    if (process.env.NODE_ENV === "development") {
      console.log("process.env: ", process.env);
    }
  }
});

store
  .dispatch("initAction", {
    isPublicPage: router.currentRoute.meta?.public
  })
  .then(() => {
    VueApp.$mount("#app");
    return VueApp;
  });
