import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/pages/login/Login.vue";
import ForgotPassword from "@/pages/forgotPassword/ForgotPassword.vue";
import ResetPassword from "@/pages/resetPassword/ResetPassword.vue";
import NotFound from "@/pages/NotFound";
import CollectionsList from "@/pages/collections/CollectionsList";
import ItemsList from "@/pages/items/ItemsList";
import UsersList from "@/pages/users/UsersList";
import ModelsList from "@/pages/models/ModelsList";
import ModelPartsMapping from "@/pages/models/ModelPartsMapping";

import AuthGuard from "./guards/auth-guard";
import PublicPageGuard from "./guards/public-page-guard";

Vue.use(VueRouter);

const PATTERNS = {
  integer: "\\b[0-9]+\\b"
};

const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    beforeEnter: PublicPageGuard,
    meta: {
      // It's not default layout, that why we need to specify it
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgotPassword,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Reset Password",
    path: "/forgot-password/reset/:token",
    component: ResetPassword,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Home",
    path: "/",
    component: CollectionsList,
    beforeEnter: AuthGuard
  },
  {
    name: "Items",
    path: "/items",
    component: ItemsList,
    beforeEnter: AuthGuard
  },
  {
    name: "Users",
    path: "/users",
    component: UsersList,
    beforeEnter: AuthGuard
  },
  {
    name: "Models",
    path: "/models",
    component: ModelsList,
    beforeEnter: AuthGuard
  },
  {
    name: "ModelPartsMapping",
    path: `/models/:modelId(${PATTERNS.integer})/mapping`,
    component: ModelPartsMapping,
    beforeEnter: AuthGuard
  },
  {
    name: "404",
    path: "/404",
    component: NotFound,
    meta: {
      layout: "empty-layout",
      public: true
    }
  },
  {
    name: "Unknown",
    path: "*",
    component: NotFound,
    meta: {
      layout: "empty-layout",
      public: true
    }
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history"
});

export default router;
