export const DataTableFooterProps = {
  computed: {
    tableFooterProps() {
      return {
        "items-per-page-options": [10, 25, 50],
        "disable-pagination": this.loading || false
      };
    }
  }
};
