/**
 * Directive for remove focus from dialog activator on close v-dialog
 */
export const BlurAllOnCloseDialogDirective = {
  update: (el, binding, vNode, oldNode) => {
    if (oldNode.data.model.value && !vNode.data.model.value) {
      if (document.activeElement) {
        setTimeout(() => {
          document.activeElement.blur();
        }, 100);
      }
    }
  }
};
