<template>
  <ValidationProvider
    :label="$attrs.label"
    :mode="$attrs.mode"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <v-file-input
      slot-scope="{ errors }"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-file-input>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "FileField",

  components: {
    ValidationProvider
  },

  props: {
    validationRules: {
      type: [String, Object]
    },
    value: {
      type: null
    }
  },

  data: () => ({
    innerValue: null
  }),

  computed: {
    rules() {
      return this.validationRules || "required|size:2000"; // size in kilobytes
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    }
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
