<template>
  <div class="text-center">
    <v-menu
      v-model="isMenuVisible"
      :close-on-content-click="false"
      :nudge-width="150"
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-lowercase text-subtitle-2"
          color="gray"
          outlined
          v-bind="attrs"
          v-on="on"
          text
          height="40"
        >
          {{ user.login }}
        </v-btn>
      </template>

      <v-card>
        <v-window>
          <v-window-item>
            <v-list class="user-menu-items" dense>
              <v-list-item-group color="primary">
                <!--                <user-profile>-->
                <!--                  <template v-slot:activator="{ openUserProfile }">-->
                <!--                    <v-list-item @click="hideMenu(openUserProfile)">-->
                <!--                      <v-list-item-icon>-->
                <!--                        <v-icon>mdi-account-box</v-icon>-->
                <!--                      </v-list-item-icon>-->
                <!--                      <v-list-item-title>Profile</v-list-item-title>-->
                <!--                    </v-list-item>-->
                <!--                  </template>-->
                <!--                </user-profile>-->

                <v-list-item @click.stop="signOut">
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Sign out</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-window-item>
        </v-window>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import UserProfile from "@/components/modals/UserProfile";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserMenu",

  // components: { UserProfile },

  data: () => ({
    user: {
      id: null,
      login: null,
      role: null
    },
    isMenuVisible: false
  }),

  methods: {
    ...mapGetters(["userData"]),
    ...mapActions(["deleteToken", "getCurrentUser"]),

    initMenu() {
      const userData = this.userData();

      if (Object.keys(userData).length === 0) {
        this.getCurrentUser().then(() => {
          this.user = this.userData();
        });
      } else {
        this.user = userData;
      }
    },

    hideMenu(callback) {
      this.isMenuVisible = false;
      callback && callback();
    },

    signOut() {
      this.deleteToken();
    }
  },

  mounted() {
    this.initMenu();
  }
};
</script>
