import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Modules
import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import navigation from "@/store/modules/navigation";
import navigation_details from "@/store/modules/navigation-details";
import global_loading from "@/store/modules/loading";
import collections from "@/store/modules/collections";
import items from "@/store/modules/items";
import models from "@/store/modules/models";
import content_import from "@/store/modules/content-import";
import materials from "@/store/modules/materials";
import content_export from "@/store/modules/content-export";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    apiPrefix: "/v1",
    loading: false
  };
};

const state = getDefaultState();

export default new Vuex.Store({
  state: state,
  mutations: {},
  actions: {},
  modules: {
    auth,
    collections,
    items,
    users,
    models,
    navigation,
    navigation_details,
    global_loading,
    content_import,
    materials,
    content_export
  },
  plugins: [
    createPersistedState({
      key: "app.data.store",
      paths: ["auth", "navigation"]
    })
  ]
});
