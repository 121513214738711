import { request } from "@/services/api";
import store from "@/store";
import { RESOURCE_TYPES } from "@/components/enums";

export default class ResourcesService {
  static async getPresignedData(filename, filetype) {
    if (!Object.prototype.hasOwnProperty.call(RESOURCE_TYPES, filetype)) {
      throw new Error(`Invalid resource type '${filetype}'`);
    }

    return await request.post(
      `${store.state.apiPrefix}/resources`,
      {
        file: filename,
        type: filetype
      },
      {
        headers: {
          Authorization: `Bearer ${store.getters.accessToken}`
        }
      }
    );
  }

  static uploadFile(file, presignedData) {
    let formData = new FormData();

    for (const [field, value] of Object.entries(presignedData.fields)) {
      formData.append(field, value);
    }

    // AWS ignores all fields in the request after the 'file' field, so all other fields must appear before the file.
    formData.append("file", file);

    return request
      .post(presignedData.url, formData, {
        external: true,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .catch(error => {
        const status = error?.response?.status;
        const data = error.response?.data;

        throw new Error(`A ${status} error occurred. Error data: ${data}`);
      });
  }
}
