import { request } from "@/services/api";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

export default {
  name: "content-export",
  state,
  actions: {
    contentExportExecute({ getters, rootState }, params) {
      const options = {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request
        .post(`${rootState.apiPrefix}/export`, null, options)
        .then(response => {
          let filename = "data.csv";
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  }
};
