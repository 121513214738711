<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        small
        dark
        v-bind="attrs"
        v-on="on"
        v-if="buttonIcon"
      >
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-btn>
      <v-btn color="primary" v-bind="attrs" v-on="on" v-else>
        {{ buttonText }}
      </v-btn>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-overlay :value="loading" :absolute="true" :opacity="0.17">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-card-text>
          <v-container>
            <v-form ref="form" :disabled="loading">
              <text-field
                label="Name"
                name="name"
                v-model="formData.name"
                validation-rules="required|max:30"
                autocomplete="off"
              ></text-field>
              <text-field
                label="Price"
                name="price"
                v-model="formData.price"
                validation-rules="required|price:2,2|min_value:0.01"
                autocomplete="off"
              ></text-field>
              <text-area-field
                rows="1"
                label="Short description"
                name="short_description"
                v-model="formData.short_description"
                validation-rules="required|max:30"
              ></text-area-field>
              <text-area-field
                label="Full description"
                name="description"
                v-model="formData.description"
                validation-rules="required|max:200"
              ></text-area-field>

              <collections-select
                v-model="formData.collection_id"
                label="Collection"
                name="collection_id"
                item-value="id"
                item-text="name"
                clearable
                validation-rules="required"
              ></collections-select>

              <autocomplete
                label="Model"
                :items="modelsList"
                v-model="formData.model"
                id-attr="id"
                key-attr="name"
                hint="Type a model name"
                validation-rules="required"
                clearable
                :fetch-callback="getModels"
                @change="onModelUpdate"
              >
              </autocomplete>

              <v-file-input
                v-if="!thumbnailPreviewUrl"
                :rules="fileRules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-image"
                label="Thumbnail"
                v-model="thumbnail"
                @change="onFileInputChanged"
              ></v-file-input>

              <span v-if="thumbnailPreviewUrl">
                <div
                  class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted"
                >
                  <div class="v-input__control">
                    <div class="v-input__slot v-input__custom_slot">
                      <div class="v-text-field__slot">
                        <label
                          class="v-label v-label--active theme--light"
                          style="left: 0; right: auto; position: absolute;"
                          >Thumbnail</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <v-img
                  :src="thumbnailPreviewUrl"
                  style="width: 100px; height: 100px; object-fit: cover"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <template v-slot:default>
                    <v-icon
                      color="primary"
                      class="remove-preview"
                      @click="onThumbnailPreviewRemove"
                      small
                      >mdi-close</v-icon
                    >
                  </template>
                </v-img>
              </span>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClosed">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="processing"
            :disabled="invalid || processing || !changed"
            @click.prevent="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { RESOURCE_TYPES } from "@/components/enums";
import { deepCopy, objectsEqual, timeout } from "@/components/helpers";
import TextField from "@/components/forms/inputs/TextField";
import TextAreaField from "@/components/forms/inputs/TextArea";
import Autocomplete from "@/components/forms/inputs/Autocomplete";
import ResourcesService from "@/services/resources";
import CollectionsSelect from "@/pages/items/components/CollectionsSelect";

const getDefaultFormData = () => {
  return {
    name: null,
    description: null,
    short_description: null,
    thumbnail_url: null,
    thumbnail_key: null,
    price: null,
    model: null,
    collection_id: null
  };
};

export default {
  name: "ItemDialog",

  components: {
    TextField,
    TextAreaField,
    Autocomplete,
    CollectionsSelect
  },

  data: () => ({
    title: null,
    loading: false,
    processing: false,
    invalid: false,
    dialog: false,
    changed: false,
    thumbnail: null,
    thumbnailPreviewUrl: null,
    formData: Object.assign({}, getDefaultFormData()),
    initialFormData: null,
    presignedData: null,
    modelsList: [],
    fileRules: [
      value =>
        !value || value.size < 2000000 || "Image size should be less than 2 MB"
    ]
  }),

  props: {
    modelId: {
      type: Number,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    buttonIcon: {
      type: String,
      default: null
    },
    callback: {
      type: Function
    }
  },

  watch: {
    dialog: function(value) {
      if (value) {
        if (this.modelId) {
          this.loading = true;
          this.getItem(this.modelId)
            .then(model => {
              this._initiateFormData(model);
            })
            .finally(() => (this.loading = false));
        }
      }
    },

    formData: {
      deep: true,
      handler: function() {
        this.changed = !objectsEqual(this.formData, this.initialFormData);
      }
    }
  },

  methods: {
    ...mapActions(["upsertItem", "getModels", "getItem"]),

    _setThumbnailPreviewUrl(value) {
      this.thumbnailPreviewUrl = value;
    },

    _getThumbnailKeyFromUrl(url) {
      if (url) {
        const path = new URL(url).pathname;
        return path.substr(1, path.length);
      }

      return null;
    },

    _resetFormData() {
      this.$refs.form.reset();
      this.$refs.validator.reset();
      this._setThumbnailPreviewUrl(null);
      this.thumbnail = null;
      this.presignedData = null;
    },

    _initiateFormData(model) {
      const formData = {
        name: model.name,
        description: model.description,
        short_description: model.short_description,
        thumbnail_url: model.thumbnail_url,
        thumbnail_key: this._getThumbnailKeyFromUrl(model.thumbnail_url),
        price: model.price,
        model: model.model,
        collection_id: model.collection_id
      };

      this.formData = deepCopy(formData);
      this.initialFormData = deepCopy(formData);

      this._setThumbnailPreviewUrl(model.thumbnail_url);
    },

    onSubmit() {
      if (this.$refs.validator.validate()) {
        this.processing = true;

        this.upsertItem({
          id: this.modelId ?? null,
          name: this.formData.name,
          description: this.formData.description,
          short_description: this.formData.short_description,
          thumbnail_key: this.formData.thumbnail_key,
          price: this.formData.price,
          model_id: this.formData.model.id,
          collection_id: this.formData.collection_id,
          thumbnail: {
            file: this.thumbnail,
            presignedData: this.presignedData
          }
        })
          .then(() => {
            timeout(5).then(() => {
              this.onClosed();
              this.callback && this.callback();
            });
          })
          .catch(error => {
            const errors = error?.response?.data?.errors ?? [];
            const parsedErrors = Object.fromEntries(
              errors.map(el => [el.field, el.message])
            );
            this.$refs.validator.setErrors(parsedErrors);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    onClosed() {
      this._resetFormData();
      this.dialog = false;
    },

    async onFileInputChanged(file) {
      if (file) {
        this._setThumbnailPreviewUrl(URL.createObjectURL(file));
        URL.revokeObjectURL(file);

        this.thumbnail = file;
        this.presignedData = await ResourcesService.getPresignedData(
          file.name,
          RESOURCE_TYPES.image
        );

        if (this.presignedData) {
          this.formData.thumbnail_key = this.presignedData?.fields?.key ?? null;
        }
      } else {
        this._setThumbnailPreviewUrl(null);
      }
    },

    onThumbnailPreviewRemove() {
      this._setThumbnailPreviewUrl(null);
      this.thumbnail = null;
      this.formData.thumbnail_key = null;
      this.formData.thumbnail_url = null;
    },

    onModelUpdate(value) {
      this.formData.model = value;
    }
  },

  created() {
    this.title = !this.modelId ? "New item" : "Edit item";
  }
};
</script>

<style scoped lang="sass">
.v-input__custom_slot:before
  border: none !important

.remove-preview:before
  position: relative
  left: 80px
  cursor: pointer

.remove-preview:after
  position: absolute
  left: 80px
</style>
