import { request } from "@/services/api";

export default {
  name: "users",

  actions: {
    getUsers({ getters, rootState }, params) {
      // Add validation to prevent send unused params to API
      const validParamKeys = ["page", "size"];
      const validParams = {};

      validParamKeys.forEach(key => {
        if (params[key]) {
          validParams[key] = params[key];
        }
      });

      const options = {
        params: validParams,
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/users`, options);
    },

    getUser({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/users/${id}`, options);
    },

    deleteUser({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.delete(`${rootState.apiPrefix}/users/${id}`, {}, options);
    },

    upsertUser({ getters, rootState }, data) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      const isNewRecord = data.id === null;
      const method = isNewRecord ? "post" : "put";
      const pathSuffix = isNewRecord ? "" : `/${data.id}`;
      const userData = {
        id: data.id,
        login: data.login,
        role: data.role,
        is_active: data.is_active,
        password: data.password,
        password_confirm: data.password_confirm
      };

      return request[method](
        `${rootState.apiPrefix}/users${pathSuffix}`,
        userData,
        options
      );
    }
  },

  mutations: {}
};
