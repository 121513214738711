<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="400px"
    v-blur-all-on-close-dialog
  >
    <!--
      We adding new position to v-dialog's props - openUserProfile. In this way we have one point/method that controls
      whether this dialog should be opened or not: parent calls openUserProfile method to open dialog.
      It's possible that it can be implemented more simpler.
    -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" :openUserProfile="openUserProfile" />
    </template>

    <validation-observer ref="form">
      <v-form ref="form" @submit.prevent="updateProfile">
        <v-card>
          <v-toolbar dark class="headline" color="primary">
            <v-toolbar-title>User Profile</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <username-field label="Username" v-model="name"></username-field>

            <email-field label="Email" v-model="email"></email-field>

            <password-field
              label="Password"
              v-model="password"
              validation-rules="min:8"
            ></password-field>

            <password-confirm-field
              label="Confirm password"
              v-model="passwordConfirm"
              validation-rules="min:8|confirm:@password"
            ></password-confirm-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="processing" @click="closeUserProfile" text>
              Close
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :disabled="processing"
              :loading="processing"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";

import EmailField from "@/components/forms/inputs/Email";
import UsernameField from "@/components/forms/inputs/Username";
import PasswordField from "@/components/forms/inputs/Password";
import PasswordConfirmField from "@/components/forms/inputs/PasswordConfirm";

extend("confirm", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords must match"
});

export default {
  name: "UserProfile",
  components: {
    EmailField,
    UsernameField,
    PasswordField,
    PasswordConfirmField
  },
  data: () => ({
    valid: true,
    isDialogVisible: false,
    processing: false,

    id: null,
    name: null,
    email: null,
    password: null,
    passwordConfirm: null
  }),
  methods: {
    ...mapGetters(["userData"]),
    ...mapActions(["updateCurrentUser"]),

    initForm() {
      const userData = this.userData();

      this.id = userData.id;
      this.name = userData.name;
      this.email = userData.email;
      this.password = null;
      this.passwordConfirm = null;
    },

    updateProfile() {
      this.$refs.form.validate();

      this.processing = true;
      const userData = {
        id: this.id,
        name: this.name,
        email: this.email,
        password: this.password
      };

      this.updateCurrentUser(userData).then(() => {
        this.processing = false;
        this.closeUserProfile();
      });
    },

    openUserProfile() {
      this.initForm();
      this.isDialogVisible = true;
    },

    closeUserProfile() {
      this.isDialogVisible = false;
    }
  }
};
</script>
