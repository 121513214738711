export const GridUtils = {
  data: () => ({
    timeout: null,
    options: {},
    totalResultsCount: 0,
    search: "",
    searchMinCharsCount: 2,
    loading: false,
    results: [],
    autoRefreshResults: false,
    autoRefreshTimer: null,
    autoRefreshInterval: 60000
  }),

  watch: {
    search: {
      handler(query) {
        if (query === "") {
          this.timeout = setTimeout(this.fetchData, 500);
        } else if (query.length < this.searchMinCharsCount) {
          clearTimeout(this.timeout);
          return false;
        }

        this.options.page = 1;
        this.totalResultsCount = 0;
        this.results = [];
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.fetchData, 500);
      }
    },

    options: {
      handler(value) {
        this.$router.push({ query: { page: value.page } }).catch(err => {});
        this.fetchData();
      },
      deep: true
    }
  },

  methods: {
    fetchData() {
      this.loading = true;
      let params = this.getQueryParams();

      this[this.getResultsActionName()](params)
        .then(({ items, metadata }) => {
          this.results = items;
          this.totalResultsCount = metadata?.pagination?.total_items;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getQueryParams() {
      return {
        page: this.options.page,
        size: this.options.itemsPerPage,
        query: this.search
      };
    },

    getResultsActionName() {
      throw new Error("Must be implemented in a subclass");
    },

    cancelAutoRefresh() {
      clearInterval(this.autoRefreshTimer);
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.options.page = parseInt(vm.$route.query.page) || 1;
    });
  },

  created() {
    if (this.autoRefreshResults) {
      this.autoRefreshTimer = setInterval(
        this.fetchData,
        this.autoRefreshInterval
      );
    }
  },

  beforeDestroy() {
    if (this.autoRefreshResults) {
      this.cancelAutoRefresh();
    }
  }
};
