<template>
  <div>
    <confirm-password-form
      @clicked="onSubmit"
      :processing="processing"
      v-if="!error"
    >
      <template v-slot:title>
        <v-container class="my-2 pa-0">
          <p class="text-left font-weight-light text-h6">
            Almost there!
          </p>
          <p class="text-left font-weight-light text-subtitle-2">
            Please set your new password:
          </p>
        </v-container>
      </template>
    </confirm-password-form>

    <v-container class="my-2 pa-0" v-if="error">
      <p class="text-left font-weight-light text-h6">
        Error
      </p>
      <p class="text-left font-weight-light text-subtitle-2">
        {{ error }}
      </p>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ConfirmPasswordForm from "@/components/forms/ConfirmPasswordForm";

export default {
  name: "ResetPassword",
  components: {
    ConfirmPasswordForm
  },
  data: () => ({
    processing: false,
    error: null
  }),
  methods: {
    ...mapActions(["resetPassword"]),
    onSubmit(values) {
      this.processing = true;
      let token = this.$route.params.token;
      this.resetPassword({
        token: token,
        credentials: {
          password: values.password,
          password_confirm: values.passwordConfirm
        }
      })
        .catch(error => {
          this.error = error;
        })
        .finally(() => {
          this.processing = false;
        });
    }
  },
  metaInfo: {
    title: "Reset Password"
  }
};
</script>
