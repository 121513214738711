import { request } from "@/services/api";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

export default {
  name: "materials",
  state,
  actions: {
    getMaterials({ getters, rootState }, params) {
      // Add validation to prevent send unused params to API
      const validParamKeys = ["page", "size"];
      const validParams = {};

      validParamKeys.forEach(key => {
        if (params[key]) {
          validParams[key] = params[key];
        }
      });

      const options = {
        params: validParams,
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/materials`, options);
    }
  },

  getters: {},

  mutations: {}
};
