var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-title',{staticClass:"pl-1"},[_vm._v(" Models ")])],1),_c('v-col',{staticClass:"mr-auto align-self-center action-buttons",attrs:{"cols":"auto"}},[_c('import-wizard',{on:{"dialog-closed":_vm.onDialogClose}}),_c('span',[_c('v-btn',{attrs:{"loading":_vm.downloading,"disabled":_vm.downloading,"color":"primary"},on:{"click":_vm.onExportClick}},[_vm._v(" Export ")])],1),_c('model-dialog',{attrs:{"button-text":"New model","callback":_vm.onDialogClose}})],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"filter"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"selectable":"","headers":_vm.headers,"items":_vm.results,"options":_vm.options,"server-items-length":_vm.totalResultsCount,"loading":_vm.loading,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.id,staticClass:"table-row"},[_c('td',{staticClass:"table-secondary-column"},[_c('span',[_vm._v(_vm._s(item.id))])]),_c('td',{staticClass:"table-secondary-column"},[_c('span',[_vm._v(_vm._s(_vm._f("default_value")(item.name)))])]),_c('td',{staticClass:"table-secondary-column"},[_c('span',{staticClass:"ucwords",class:{ loading: _vm.isProcessing(item) }},[_vm._v(_vm._s(_vm._f("default_value")(item.conversion_result ? item.conversion_result.status : null)))])]),_c('td',{staticClass:"table-secondary-column"},[_c('span',[_vm._v(_vm._s(_vm._f("default_value")(item.custom_id)))])]),_c('td',{staticClass:"table-secondary-column"},[_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","disabled":!_vm.isConverted(item),"to":{
                name: 'ModelPartsMapping',
                params: {
                  modelId: item.id
                }
              }}},[_c('v-icon',[_vm._v("mdi-unity")])],1),_c('model-dialog',{attrs:{"button-icon":"mdi-pencil-circle","model-id":item.id,"callback":_vm.onDialogClose,"button-disabled":_vm.isProcessing(item)}}),_c('v-btn',{attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.onModelDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete-circle")])],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }