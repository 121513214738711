<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Users
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="table-secondary-column">
              <span>{{ item.id }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ item.login | default_value }}</span>
            </td>
            <td class="table-secondary-column">
              <span>{{ userRoles[item.role] }}</span>
            </td>
            <td class="table-secondary-column">
              <v-simple-checkbox
                v-model="item.is_active"
                disabled
              ></v-simple-checkbox>
            </td>
            <td class="table-secondary-column">
              <user-dialog
                button-icon="mdi-pencil-circle"
                :model-id="item.id"
                :callback="onDialogClose"
              ></user-dialog>
              <v-btn color="primary" icon small @click="onUserDelete(item)">
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { ROLES_WITH_TITLES } from "@/components/enums";
import UserDialog from "@/pages/users/UserDialog";

export default {
  name: "UsersList",

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  components: { UserDialog },

  data: () => ({
    loading: false,
    userRoles: ROLES_WITH_TITLES,
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "id"
      },
      {
        text: "Login",
        sortable: false
      },
      {
        text: "Role",
        sortable: false
      },
      {
        text: "Active",
        sortable: false
      },
      {
        text: null,
        align: "end",
        sortable: false,
        width: "7%"
      }
    ]
  }),

  methods: {
    ...mapActions(["getUsers", "deleteUser"]),

    getResultsActionName() {
      return "getUsers";
    },

    onDelete(user) {
      this.loading = true;
      this.deleteUser(user.id).then(() => {
        this.fetchData();
      });
    },

    onUserDelete(user) {
      this.$confirm("Are you sure you want to remove this user?", {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      }).then(result => {
        if (result) {
          this.onDelete(user);
        }
      });
    },

    onDialogClose() {
      this.fetchData();
    }
  },

  metaInfo: {
    title: "Users"
  }
};
</script>

<style scoped></style>
