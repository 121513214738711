<template>
  <v-flex xs12>
    <ValidationProvider
      :label="$attrs.label"
      :mode="$attrs.mode"
      :name="$attrs.name"
      :rules="validationRules"
    >
      <v-autocomplete
        v-model="innerValue"
        deletable-chips
        return-object
        hide-selected
        append-icon=""
        :hide-no-data="!search || isLoading"
        :loading="isLoading"
        :search-input.sync="search"
        :items="innerItems"
        :item-text="keyAttr"
        :item-value="idAttr"
        :menu-props="{ closeOnContentClick: true }"
        v-bind="$attrs"
        v-on="$listeners"
        @change="clearSearch"
        @update:search-input="processInput"
        slot-scope="{ errors }"
        :error-messages="errors"
      >
        <template slot="append-outer">
          <slot name="append-outer"></slot>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-flex>
</template>

<script>
export default {
  name: "Autocomplete",

  data() {
    return {
      innerItems: [],
      isLoading: false,
      innerValue: null,
      search: null,
      timeout: null,
      searchMinCharsCount: 2
    };
  },

  props: {
    value: {
      type: [Array, Object, Number],
      default: () => {}
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    fetchCallback: {
      type: Function,
      default: null
    },
    validationRules: {
      type: [String, Object]
    },
    keyAttr: {
      type: String,
      default: null
    },
    idAttr: {
      type: String,
      default: "id"
    }
  },

  created() {
    // converting key => value object to an appropriate format (array of arrays).
    if (typeof this.items === "object" && !Array.isArray(this.items)) {
      this.innerItems = Object.entries(this.items);
    }

    // default selection
    this.innerValue = this.value;

    if (this.value) {
      this.search = this.value[this.keyAttr];
    }
  },

  watch: {
    search(newVal) {
      if (this.isLoading) {
        return;
      }

      if (!newVal || newVal.length < this.searchMinCharsCount) {
        return;
      }

      if (this.fetchCallback) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.fetchData, 500, newVal);
      }
    },

    value(newVal) {
      this.innerValue = newVal;
    }
  },

  methods: {
    fetchData(query) {
      this.isLoading = true;

      this.fetchCallback({
        page: 1,
        size: 20,
        query: query
      })
        .then(data => {
          this.innerItems = this.innerItems.concat(data.items);
        })
        .finally(() => (this.isLoading = false));
    },

    clearSearch() {
      this.search = "";
    },

    processInput() {
      if (this.search && this.search.split(",").length > 1) {
        this.innerValue = this.innerValue.concat(
          this.search.split(",").filter(term => !this.innerValue.includes(term))
        );

        this.clearSearch();
      }
    }
  }
};
</script>
