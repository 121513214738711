import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VuetifyConfirm from "vuetify-confirm";

const vuetify = new Vuetify({
  theme: {
    // This option may cause some issue with IE 11:
    // https://vuetifyjs.com/en/features/theme/#custom-properties
    options: { customProperties: true }
  }
});

Vue.use(Vuetify);
Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
