<template>
  <v-stepper-content :step="stepId" class="pa-0 notransition">
    <validation-observer ref="validator" v-slot="{ invalid }">
      <v-card-text>
        <v-row class="d-flex flex-column" dense v-if="loading">
          <import-progress :message="progressMessage"></import-progress>
        </v-row>
        <v-row v-else class="d-flex flex-column pt-6 import-main-block" dense>
          <template v-if="!completed">
            <v-form ref="form" :disabled="loading">
              <v-row>
                <v-col>
                  <text-field
                    label="URL"
                    name="url"
                    v-model="formData.url"
                    validation-rules="required|url:true"
                    autocomplete="off"
                    hint="Please insert the link to the Amazon S3 folder with models "
                  ></text-field>

                  <text-field
                    label="Scale"
                    name="scale"
                    v-model.number="formData.scale"
                    validation-rules="required|float|min_value:0"
                    autocomplete="off"
                  ></text-field>

                  <text-field
                    label="Position"
                    name="position"
                    hint="A comma-separated X,Y,Z coordinates. Example: 0,0,0"
                    validation-rules="required|coordinates"
                    :value="formData.position"
                    autocomplete="off"
                    @change="onPositionChange"
                  ></text-field>

                  <text-field
                    label="Rotation"
                    name="rotation"
                    hint="A comma-separated X,Y,Z coordinates. Example: 0,0,0"
                    validation-rules="required|coordinates"
                    :value="formData.rotation"
                    autocomplete="off"
                    @change="onRotationChange"
                  ></text-field>

                  <text-field
                    label="Custom ID delimiter"
                    name="custom_id_delimiter"
                    v-model="formData.custom_id_delimiter"
                    validation-rules="max:5"
                    autocomplete="off"
                    hint="Delimiter allows the identification of the Custom ID in an original file name."
                  ></text-field>

                  <v-checkbox
                    v-model="formData.remove_source"
                    label="Remove source files?"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-else>
            <div>
              <p>Imported {{ model.result.success }} record(s).</p>
              <p v-if="model.result.failure">
                Failed to import {{ model.result.failure }} record(s).
              </p>
            </div>
          </template>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClose" v-if="!completed">Close</v-btn>
        <v-btn
          text
          v-if="!completed"
          color="primary"
          @click="runImport"
          :disabled="invalid || loading"
          >Import</v-btn
        >
        <v-btn v-else text color="primary" @click="onClose">Done</v-btn>
      </v-card-actions>
    </validation-observer>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";
import { IMPORT_STATUS, IMPORT_WIZARD_STEPS } from "@/components/enums";
import { timeout } from "@/components/helpers";
import TextField from "@/components/forms/inputs/TextField";
import ImportProgress from "@/components/import/ImportProgress";

const IMPORT_PROGRESS_MONITORING_INTERVAL = 5000;

const getDefaultFormData = () => {
  return {
    url: null,
    remove_source: false,
    scale: 27.5,
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    custom_id_delimiter: "_"
  };
};

export default {
  name: "ImportDataStep",

  components: { ImportProgress, TextField },

  data: () => ({
    completed: false,
    loading: false,
    formData: Object.assign({}, getDefaultFormData()),
    progressMessage:
      "Please wait while we importing your data.. You may close this window and your data will appear after some time."
  }),

  props: {
    model: {
      type: Object
    }
  },

  computed: {
    stepId() {
      return IMPORT_WIZARD_STEPS.import;
    }
  },

  methods: {
    ...mapActions(["contentImportExecute", "contentImportStatus"]),

    _parseCoordinates(value) {
      return value
        .toString()
        .split(",")
        .filter(el => el !== "")
        .map(Number)
        .filter(el => !isNaN(el));
    },

    _resetFormData() {
      this.formData = Object.assign({}, getDefaultFormData());
      this.$refs.validator.reset();
    },

    onClose() {
      this.completed = false;
      this.loading = false;
      this._resetFormData();
      this.$emit("dialog-closed");
    },

    onPositionChange(value) {
      this.formData.position = this._parseCoordinates(value);
    },

    onRotationChange(value) {
      this.formData.rotation = this._parseCoordinates(value);
    },

    async runImport() {
      const params = {
        scale: this.formData.scale,
        position: this.formData.position,
        rotation: this.formData.rotation,
        remove_source: this.formData.remove_source,
        custom_id_delimiter: this.formData.custom_id_delimiter,
        url: this.formData.url
      };

      this.loading = true;

      try {
        let data = await this.contentImportExecute(params);

        if (data) {
          params.id = data.id;
          await this.waitUntilProcessed(params);
        }
      } finally {
        this.loading = false;
      }
    },

    async waitUntilProcessed(params) {
      let response = await this.contentImportStatus(params);

      if (response?.status !== IMPORT_STATUS.processed) {
        await timeout(IMPORT_PROGRESS_MONITORING_INTERVAL);
        await this.waitUntilProcessed(params);
      } else {
        this.$emit("update-model", response || {});
        this.completed = true;
        return Promise.resolve();
      }
    }
  }
};
</script>

<style scoped lang="sass">
.import-main-block
  height: 350px
</style>
