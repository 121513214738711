import moment from "moment";
import { DATE_FORMAT } from "@/components/enums";

function date(value, format = DATE_FORMAT) {
  if (value) {
    return moment(String(value)).format(format);
  }

  return "–";
}

export { date };
