<template>
  <ValidationProvider
    :label="$attrs.label"
    name="passwordConfirm"
    :rules="rules"
  >
    <v-text-field
      type="password"
      slot-scope="{ errors }"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "PasswordConfirmField",
  components: {
    ValidationProvider
  },
  props: {
    validationRules: {
      type: String
    },
    value: {
      type: null
    }
  },
  computed: {
    rules() {
      return this.validationRules || "required|min:8|confirm:@password";
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
