<template>
  <v-app>
    <v-app-bar app clipped-left class="white">
      <v-app-bar-nav-icon @click="toggleSidebar" />
      <v-toolbar-title
        ><router-link to="/" class="link-as-text">{{
          appName
        }}</router-link></v-toolbar-title
      >
      <span class="text-caption ml-1 mt-2">v.{{ appVersion }}</span>

      <v-spacer></v-spacer>

      <user-menu></user-menu>

      <v-progress-linear
        height="3"
        :active="globalLoading"
        :indeterminate="globalLoading"
        absolute
        bottom
      ></v-progress-linear>
    </v-app-bar>

    <sidebar-menu
      :items="this.pageMenuItems"
      :toggle="miniSideBar"
      :prev-page="this.prevPage"
    ></sidebar-menu>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UserMenu from "@/components/menus/UserMenu";
import SidebarMenu from "@/components/menus/SidebarMenu";

export default {
  components: {
    SidebarMenu,
    UserMenu
  },

  computed: {
    ...mapGetters([
      "userCurrentAccount",
      "pageMenuItems",
      "prevPage",
      "pageDetailsSectionComponent",
      "pageDetailsSectionComponentProps",
      "globalLoading"
    ]),

    ...mapState({
      miniSideBar: state => state.navigation.miniSideBar
    })
  },

  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
    appName: process.env.VUE_APP_NAME
  }),

  methods: {
    toggleSidebar() {
      this.$store.commit("setMiniSideBar", !this.miniSideBar);
    }
  }
};
</script>
