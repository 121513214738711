import { mapActions } from "vuex";
import store from "@/store/index";

export const PageMenu = {
  computed: {
    getMenuItems: function() {
      return [
        {
          title: "Collections",
          icon: "mdi-folder-multiple",
          routerName: "Home",
          visible: true
        },
        {
          title: "Items",
          icon: "mdi-format-list-bulleted",
          routerName: "Items",
          visible: true
        },
        {
          title: "Models",
          icon: "mdi-ring",
          routerName: "Models",
          visible: true
        },
        {
          title: "Users",
          icon: "mdi-account-multiple",
          routerName: "Users",
          visible: true
        }
      ];
    },

    hasBackButton: function() {
      return false;
    }
  },

  methods: {
    ...mapActions(["storeMenuItems", "storePrevPage", "storePageHasBackButton"])
  },

  created() {
    this.storeMenuItems(this.getMenuItems);
    this.storePageHasBackButton(this.hasBackButton);
  },

  beforeRouteEnter(to, from, next) {
    if (from.meta.canBeUsedAsBackButtonTarget) {
      store.dispatch("storePrevPage", from.path);
    }

    next();
  }
};
