const getDefaultState = () => {
  return {
    pageDetailsSectionComponent: null,
    pageDetailsSectionComponentProps: {}
  };
};

const state = getDefaultState();

export default {
  name: "navigation-details",
  state,

  actions: {
    storePageDetailsSectionComponent({ commit }, componentName) {
      commit("setPageDetailsSectionComponent", componentName);
    },

    storePageDetailsSectionComponentProps({ commit }, props) {
      commit("setPageDetailsSectionComponentProps", props);
    },

    resetPageDetailsSectionComponentProps({ commit }) {
      commit("setPageDetailsSectionComponentProps", {});
    }
  },

  getters: {
    pageDetailsSectionComponent(state) {
      return state.pageDetailsSectionComponent;
    },

    pageDetailsSectionComponentProps(state) {
      return state.pageDetailsSectionComponentProps;
    }
  },

  mutations: {
    setPageDetailsSectionComponent(state, componentName) {
      state.pageDetailsSectionComponent = componentName;
    },

    setPageDetailsSectionComponentProps(state, props) {
      state.pageDetailsSectionComponentProps = props;
    }
  }
};
