import { request } from "@/services/api";
import ResourcesService from "@/services/resources";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

export default {
  name: "collections",
  state,
  actions: {
    getModels({ getters, rootState }, params) {
      // Add validation to prevent send unused params to API
      const validParamKeys = ["page", "size", "query"];
      const validParams = {};

      validParamKeys.forEach(key => {
        if (params[key]) {
          validParams[key] = params[key];
        }
      });

      const options = {
        params: validParams,
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/models`, options);
    },

    deleteModel({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.delete(`${rootState.apiPrefix}/models/${id}`, {}, options);
    },

    getModel({ getters, rootState }, id) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/models/${id}`, options);
    },

    upsertModel({ getters, rootState }, data) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      const isNewRecord = data.id === null;
      const method = isNewRecord ? "post" : "patch";
      const pathSuffix = isNewRecord ? "" : `/${data.id}`;
      const modelData = {
        id: data.id,
        name: data.name,
        scale: data.scale,
        custom_id: data.custom_id,
        position: data.position,
        rotation: data.rotation
      };

      if (data.key) {
        modelData.key = data.key;
      }

      if (data.parts_with_materials_mapping) {
        modelData.parts_with_materials_mapping =
          data.parts_with_materials_mapping;
      }

      if (data.default_materials) {
        modelData.default_materials = data.default_materials;
      }

      if (data.parts) {
        modelData.parts = data.parts;
      }

      if ("file" in data && data.file.file) {
        return ResourcesService.uploadFile(
          data.file.file,
          data.file.presignedData
        ).then(() => {
          return request[method](
            `${rootState.apiPrefix}/models${pathSuffix}`,
            modelData,
            options
          );
        });
      } else {
        return request[method](
          `${rootState.apiPrefix}/models${pathSuffix}`,
          modelData,
          options
        );
      }
    }
  },

  getters: {},

  mutations: {}
};
