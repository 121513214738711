export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function objectsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function groupBy(array, key) {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
}

export function sliceObjectKeys(obj, keysFilterFn) {
  return Object.fromEntries(
    Object.keys(obj)
      .filter(key => keysFilterFn(key))
      .map(key => [key, obj[key]])
  );
}
