export const RESOURCE_TYPES = {
  model: "model",
  ml_model: "ml_model",
  image: "image"
};

export const ROLES = {
  regular_user: "regular_user",
  admin: "admin",
  ml_developer: "ml_developer"
};

export const ROLES_WITH_TITLES = {
  regular_user: "User",
  admin: "Administrator",
  ml_developer: "ML Developer"
};

export const ROLES_SELECTION_OPTIONS = [
  {
    title: ROLES_WITH_TITLES[ROLES.admin],
    value: ROLES.admin
  },
  {
    title: ROLES_WITH_TITLES[ROLES.regular_user],
    value: ROLES.regular_user
  },
  {
    title: ROLES_WITH_TITLES[ROLES.ml_developer],
    value: ROLES.ml_developer
  }
];

export const DATE_FORMAT = "MMM DD, YYYY";

export const IMPORT_STATUS = {
  processing: "processing",
  processed: "processed"
};

export const MODEL_CONVERSION_STATUS = {
  processing: "processing",
  converted: "converted",
  failed: "failed"
};

export const IMPORT_WIZARD_STEPS = {
  import: "import"
};

export const PART_TYPES = {
  base: "base",
  gem: "gem",
  layer: "layer"
};

// color presets for most common parts
export const PART_COLOR_MAP = {
  // base
  [PART_TYPES.base + "1"]: "#FFFFE0",
  [PART_TYPES.base + "2"]: "#FFFFCC",
  [PART_TYPES.base + "3"]: "#FFFACD",
  [PART_TYPES.base + "4"]: "#FFFF00",
  [PART_TYPES.base + "5"]: "#FFEF00",

  // gem
  [PART_TYPES.gem + "1"]: "#801818",
  [PART_TYPES.gem + "2"]: "#DC143C",
  [PART_TYPES.gem + "3"]: "#CD5C5C",
  [PART_TYPES.gem + "4"]: "#DE3163",
  [PART_TYPES.gem + "5"]: "#900020",

  // layer
  [PART_TYPES.layer + "1"]: "#99ff99",
  [PART_TYPES.layer + "2"]: "#336600",
  [PART_TYPES.layer + "3"]: "#30D5C8",
  [PART_TYPES.layer + "4"]: "#79F8F8",
  [PART_TYPES.layer + "5"]: "#50C878"
};
