import { request } from "@/services/api";

const getDefaultState = () => {
  return {
    id: null,
    status: null,
    result: {
      success: 0,
      failure: 0
    }
  };
};

const state = getDefaultState();

export default {
  name: "content-import",
  state,
  actions: {
    contentImportExecute({ getters, rootState }, params) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.post(
        `${rootState.apiPrefix}/import`,
        {
          scale: params.scale,
          position: params.position,
          rotation: params.rotation,
          remove_source: params.remove_source,
          custom_id_delimiter: params.custom_id_delimiter,
          url: encodeURI(params.url)
        },
        options
      );
    },

    contentImportStatus({ getters, rootState }, params) {
      const options = {
        headers: {
          Authorization: `Bearer ${getters.accessToken}`
        }
      };

      return request.get(`${rootState.apiPrefix}/import/${params.id}`, options);
    }
  },

  getters: {
    getNewImportModel(state) {
      return state;
    }
  }
};
