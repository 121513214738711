<template>
  <v-tooltip right :disabled="tooltipDisabled" :open-delay="tooltipOpenDelay">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :exact="!item.highlightDuringPartialRouteMatch"
        :to="{ name: item.routerName }"
        :class="item.routerName === $route.name ? 'router-link-active' : ''"
        class="list-item text-body-1"
        active-class="active-list-item"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon class="mr-2">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span>{{ item.onHoverTitle || item.title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SidebarMenuSingleItem",

  props: {
    item: {
      type: Object
    },
    tooltipDisabled: {
      type: Boolean,
      default: false
    },
    tooltipOpenDelay: {
      type: Number,
      default: 0
    }
  }
};
</script>
