import {
  required,
  email,
  min,
  max,
  regex,
  between,
  integer,
  double,
  min_value,
  size
} from "vee-validate/dist/rules";
import { extend, localize } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("min", {
  ...min,
  message: "Too short value."
});

extend("max", {
  ...max,
  message: "Too long value."
});

extend("regex", {
  ...regex,
  message: "Invalid format"
});

extend("confirm", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords must match"
});

extend("maxItems", {
  params: ["max_value"],
  message: "Too many items",
  validate(value, { max_value }) {
    return value.length <= max_value;
  }
});

extend("between", {
  ...between,
  message: "Value must be in between {min} and {max}"
});

extend("integer", {
  ...integer,
  message: "Value must be a valid integer"
});

extend("price", {
  ...double,
  message: "Value must be a valid price"
});

extend("float", {
  ...double,
  message: "Value must be a valid float"
});

extend("min_value", {
  ...min_value,
  message: "Value must be greater than {min}"
});

extend("size", {
  ...size,
  message: "File size must be less than {size} KB"
});

extend("extensions", {
  params: ["extensions"],
  validate(value, { extensions }) {
    if (typeof extensions === "string") {
      extensions = [extensions];
    }

    if ("File" in window && value instanceof File) {
      const extension = value.name
        .split(".")
        .pop()
        .toLowerCase();

      return extensions.includes(extension);
    }

    return true;
  },
  message: `Allowed extension(s): {extensions}`
});

extend("object", {
  validate(value) {
    try {
      const parsed = JSON.parse(value);

      if (parsed && typeof parsed === "object" && !Array.isArray(parsed)) {
        return true;
      }
    } catch {
      return false;
    }
  },
  message: `Value must be a valid JSON object`
});

extend("coordinates", {
  validate(value) {
    value = value
      .toString()
      .split(",")
      .filter(el => el !== "")
      .map(Number);

    return (
      value.length === 3 &&
      value.map(Number).every(el => typeof el === "number" && !isNaN(el))
    );
  },
  message: `Invalid format`
});

extend("url", {
  validate(value) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(encodeURI(value));
  },
  message: "Please enter a valid link."
});

localize({
  en: {
    names: {
      passwordConfirm: "Password",
      password: "Password"
    },
    fields: {
      password: {
        min: "{_field_} is too short."
      },
      passwordConfirm: {
        min: "{_field_} is too short."
      }
    }
  }
});
