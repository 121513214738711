<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        small
        dark
        v-bind="attrs"
        v-on="on"
        v-if="buttonIcon"
      >
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-btn>
      <v-btn color="primary" v-bind="attrs" v-on="on" v-else>
        {{ buttonText }}
      </v-btn>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-overlay :value="loading" :absolute="true" :opacity="0.17">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-card-text>
          <v-container>
            <v-form ref="form" :disabled="loading">
              <text-field
                label="Login"
                name="login"
                v-model="formData.login"
                validation-rules="required|email"
                autocomplete="off"
              ></text-field>

              <v-select
                label="Role"
                name="role"
                v-model="formData.role"
                :items="userRoles"
                item-text="title"
                item-value="value"
                validation-rules="required"
              ></v-select>

              <password-field
                label="Password"
                v-model="formData.password"
                validation-rules="min:8|max:64"
              ></password-field>

              <password-confirm-field
                label="Confirm password"
                name="password_confirm"
                v-model="formData.password_confirm"
                validation-rules="min:8|max:64|confirm:@password"
              ></password-confirm-field>

              <v-checkbox
                v-model="formData.is_active"
                name="is_active"
                label="Active"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClosed">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="processing"
            :disabled="invalid || processing || !changed"
            @click.prevent="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { deepCopy, objectsEqual } from "@/components/helpers";
import { ROLES_SELECTION_OPTIONS } from "@/components/enums";
import PasswordField from "@/components/forms/inputs/Password";
import PasswordConfirmField from "@/components/forms/inputs/PasswordConfirm";
import TextField from "@/components/forms/inputs/TextField";

const getDefaultFormData = () => {
  return {
    login: null,
    role: null,
    is_active: null,
    password: null,
    password_confirm: null
  };
};

export default {
  name: "UserDialog",
  components: {
    TextField,
    PasswordField,
    PasswordConfirmField
  },
  data: () => ({
    title: null,
    loading: false,
    processing: false,
    invalid: false,
    dialog: false,
    changed: false,
    thumbnailPreviewUrl: null,
    formData: Object.assign({}, getDefaultFormData()),
    initialFormData: null,
    userRoles: ROLES_SELECTION_OPTIONS
  }),
  props: {
    modelId: {
      type: Number,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    buttonIcon: {
      type: String,
      default: null
    },
    callback: {
      type: Function
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        if (this.modelId) {
          this.loading = true;
          this.getUser(this.modelId)
            .then(model => {
              this._initiateFormData(model);
            })
            .finally(() => (this.loading = false));
        }
      }
    },

    formData: {
      deep: true,
      handler: function() {
        this.changed = !objectsEqual(this.formData, this.initialFormData);
      }
    }
  },
  methods: {
    ...mapActions(["upsertUser", "getUser"]),

    _resetFormData() {
      this.$refs.form.reset();
      this.$refs.validator.reset();
    },

    _initiateFormData(model) {
      const formData = {
        id: this.modelId ?? null,
        login: model.login,
        role: model.role,
        is_active: model.is_active
      };

      this.formData = deepCopy(formData);
      this.initialFormData = deepCopy(formData);
    },

    onSubmit() {
      if (this.$refs.validator.validate()) {
        this.processing = true;

        this.upsertUser(this.formData)
          .then(() => {
            this.onClosed();
            this.callback && this.callback();
          })
          .catch(error => {
            const errors = error?.response?.data?.errors ?? [];
            const parsedErrors = Object.fromEntries(
              errors.map(el => [el.field, el.message])
            );
            this.$refs.validator.setErrors(parsedErrors);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    onClosed() {
      this._resetFormData();
      this.dialog = false;
    }
  },

  created() {
    this.title = !this.modelId ? "New user" : "Edit user";
  }
};
</script>

<style scoped lang="sass"></style>
