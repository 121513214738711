<template>
  <ValidationProvider
    :label="$attrs.label"
    :mode="$attrs.mode"
    :name="$attrs.name"
    :rules="validationRules"
  >
    <v-select
      :items="collections"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      slot-scope="{ errors }"
      :error-messages="errors"
    ></v-select>
  </ValidationProvider>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  name: "CollectionsSelect",

  components: {
    ValidationProvider
  },

  data: () => ({
    collections: [],
    innerValue: null
  }),

  props: {
    validationRules: {
      type: [String, Object]
    },
    value: {
      type: null
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    }
  },

  methods: {
    ...mapActions(["getCollections"]),

    fetchCollections() {
      // The first 50 collections would be enough IMO
      const params = {
        page: 1,
        size: 50
      };

      this.getCollections(params).then(response => {
        this.collections = response.items.map(el => ({
          id: el.id,
          name: el.name
        }));
      });
    }
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }

    this.fetchCollections();
  }
};
</script>

<style scoped></style>
