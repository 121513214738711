<template>
  <v-card
    class="mx-auto mt-12 pa-4 public-app-layout"
    elevation="3"
    width="500"
  >
    <!--    <v-container class="d-flex flex-column align-center">-->
    <!--      <v-img-->
    <!--        max-height="100"-->
    <!--        min-height="50"-->
    <!--        max-width="150"-->
    <!--        :src="require('/public/logo-bw.png')"-->
    <!--      ></v-img>-->
    <!--    </v-container>-->

    <v-app>
      <router-view></router-view>
    </v-app>
  </v-card>
</template>

<script>
export default {};
</script>

<!-- Didn't find a way to make it work with "scoped" mode -->
<style>
.public-app-layout .v-application .v-application--wrap {
  min-height: auto;
}
</style>
