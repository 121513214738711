<template>
  <validation-observer ref="form">
    <v-form>
      <email-field label="Login" v-model="email" mode="lazy"></email-field>

      <password-field
        label="Password"
        v-model="password"
        mode="lazy"
        validation-rules="required"
      ></password-field>

      <v-card-actions class="pa-0">
        <router-link class="text-subtitle-2" to="forgot-password"
          >Forgot password?
        </router-link>

        <v-spacer></v-spacer>

        <v-btn
          :disabled="processing"
          :loading="processing"
          color="primary"
          type="submit"
          @click.prevent="onSubmit"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from "vuex";

import EmailField from "@/components/forms/inputs/Email";
import PasswordField from "@/components/forms/inputs/Password";

export default {
  name: "Login",
  components: {
    EmailField,
    PasswordField
  },
  data: () => ({
    processing: false,
    password: "",
    email: ""
  }),
  methods: {
    ...mapActions(["getToken"]),
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.processing = true;

        const data = {
          login: this.email,
          password: this.password
        };

        this.getToken(data)
          .catch(error => {
            const errors = error?.response?.data?.errors ?? [
              { message: "Invalid credentials" }
            ];

            const message = errors?.[0]?.message;

            this.$refs.form.setErrors({ email: message });
          })
          .finally(() => {
            this.processing = false;
          });
      });
    }
  },
  metaInfo: {
    title: "Login"
  }
};
</script>
